.announcement-strip {
  width: 100%;
  background: #f8d7da;
  color: #721c24;
  overflow: hidden;
  white-space: nowrap;
}
.main-menu-container {
  background-color: black;
}
.announcement-text {
  display: inline-block;
  padding: 10px 0;
  animation: scroll 20s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.search-body {
  position: relative;
}

.search-form {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  background: #fff;
  padding: 10px;
  border: 1px solid #ddd;
  z-index: 1000;
}

.search-close {
  background: none;
  border: none;
  color: #000;
  cursor: pointer;
}

.navbar-brand {
  font-size: 24px;
  color: #fff;
}

.navbar-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.navbar-menu ul li {
  margin-right: 20px;
}

.navbar-menu ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
}

/* Hamburger Icon Styles */
.hamburger {
  display: none;
  cursor: pointer;
  width: 30px;
  height: 25px;
  flex-direction: column;
  justify-content: space-between;
}

.hamburger span {
  background: #fff;
  height: 3px;
  border-radius: 2px;
  transition: 0.3s;
}

.hamburger.open span:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger.open span:nth-child(2) {
  opacity: 0;
}

.hamburger.open span:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

/* Media Queries */
@media (max-width: 768px) {
  .navbar-menu {
    display: none;
    flex-direction: column;
    background-color: #333;
    position: absolute;
    top: 60px;
    right: 0;
    width: 100%;
    text-align: center;
  }

  .navbar-menu.open {
    display: flex;
  }

  .navbar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .hamburger {
    display: flex;
  }

  .nav-menu ul {
    flex-direction: column;
    width: 100%;
  }

  .nav-menu ul li {
    margin: 10px 0;
  }
}
