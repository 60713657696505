/* AdminWrapper.css */

.admin-content {
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.sidebar-col {
  background-color: #343a40;
  color: white;
  min-height: 100vh;
}

.content-col {
  padding: 20px;
}

.admin-wrapper {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

h2 {
  font-size: 1.5rem;
  color: #343a40;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.form-control {
  border-radius: 4px;
  box-shadow: none;
  border-color: #ced4da;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
/* ContactWrapper.css */

/* Container and Row Styles */
.container-fluid {
  padding: 0 15px;
}

.sidebar-col {
  background-color: #f8f9fa;
  padding: 15px;
}

.content-col {
  padding: 15px;
}

/* Admin Content Styles */
.admin-content {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

/* Table Styles */
.table {
  margin-bottom: 0;
}

.table thead th {
  background-color: #343a40;
  color: #ffffff;
  font-weight: bold;
}

.table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

/* Button Styles */
.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #004085;
}

/* Form Control Styles */
.form-control {
  border-radius: 4px;
  border: 1px solid #ced4da;
  padding: 0.75rem 1.25rem;
}

.form-control:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.25);
}
