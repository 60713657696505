	/*
  	Flaticon icon font: Flaticon
  	Creation date: 22/04/2018 12:48
  	*/

    @font-face {
      font-family: "Flaticon";
      src: url("../fonts/Flaticon.html");
      src: url("../fonts/Flaticond41d.html?#iefix") format("embedded-opentype"),
      url("../fonts/Flaticon.woff") format("woff"),
      url("../fonts/Flaticon.ttf") format("truetype"),
      url("../fonts/Flaticon.svg#Flaticon") format("svg");
      font-weight: normal;
      font-style: normal;
    }

    @media screen and (-webkit-min-device-pixel-ratio:0) {
      @font-face {
        font-family: "Flaticon";
        src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
      }
    }

    [class^="flaticon-"]:before, [class*=" flaticon-"]:before,
    [class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
      font-family: Flaticon;
      font-style: normal;
      line-height: 0;
      font-weight: 700;
    }

    .flaticon-pie-chart:before { content: "\f100"; }
    .flaticon-ruler-and-pencil:before { content: "\f101"; }
    .flaticon-clipboards:before { content: "\f102"; }
    .flaticon-technology-2:before { content: "\f103"; }
    .flaticon-technology-1:before { content: "\f104"; }
    .flaticon-cogwheel:before { content: "\f105"; }
    .flaticon-dna:before { content: "\f106"; }
    .flaticon-business:before { content: "\f107"; }
    .flaticon-app-store:before { content: "\f108"; }
    .flaticon-artist-tools:before { content: "\f109"; }
    .flaticon-technology:before { content: "\f10a"; }
    .flaticon-list:before { content: "\f10b"; }
    .flaticon-clipboard-with-pencil:before { content: "\f10c"; }
    .flaticon-maths-class-materials-cross-of-a-pencil-and-a-ruler:before { content: "\f10d"; }
    .flaticon-group:before { content: "\f10e"; }
    .flaticon-favorites-button:before { content: "\f10f"; }
    .flaticon-book:before { content: "\f110"; }
    .flaticon-graduation-hat:before { content: "\f111"; }