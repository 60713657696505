/*----------------------------------------------------


Table of Content:

01/ Variables,Global

02/ Header Section

03/ Slider Section

04/ Search Course Section

05/ Popular Course Section

06/ About Section

07/ Testimonial Section

08/ Latest area Section

09/ Best course Section

10/ Teacher Section

11/ Best product section

12/ FAQ section

13/ Course category section

14/ why choose us section

15/ contact section

16/ blog section

17/ check out section

18/ Course section

19/ footer

 





=====================================================================*/
/*Variables*/
/*----------------------------------------------------*/
@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,300i,400,400i,500,700,700i,900,900i");
#course-btn .genius-btn {
  margin: 0 auto;
}

/*---------------------------------------------------- */
/*global area*/
/*----------------------------------------------------*/
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-size: 16px;
  line-height: 1.4;
  color: #777777;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
}

.ul-li ul {
  margin: 0;
  padding: 0;
}
.ul-li ul li {
  list-style: none;
  display: inline-block;
}

.animation,
.staggered-animation {
  visibility: hidden;
  -webkit-transition: 0.5s ease all;
  -o-transition: 0.5s ease all;
  transition: 0.5s ease all;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.ul-li-block ul {
  margin: 0;
  padding: 0;
}
.ul-li-block ul li {
  list-style: none;
  display: block;
}

div#preloader {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  overflow: visible;
  background-color: #fff;
  background: #fff url("../img/preloader.gif") no-repeat center center;
}

a {
  text-decoration: none;
  color: inherit;
}
a:hover,
a:focus {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

section {
  overflow: hidden;
}

button {
  cursor: pointer;
}

.form-control:focus,
button:visited,
button.active,
button:hover,
button:focus,
input:visited,
.register-form-area select:visited,
.register-form-area textarea:visited,
input.active,
.register-form-area select.active,
.register-form-area textarea.active,
input:hover,
.register-form-area select:hover,
.register-form-area textarea:hover,
input:focus,
.register-form-area select:focus,
.register-form-area textarea:focus,
textarea:hover,
textarea:focus,
a:hover,
a:focus,
a:visited,
a.active,
select,
select:hover,
select:focus,
select:visited {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-decoration: none;
  color: inherit;
}

.form-control {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.relative-position {
  position: relative;
}

.headline h1,
.headline h2,
.headline h3,
.headline h4,
.headline h5,
.headline h6 {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
}

.mb20 {
  margin-bottom: 20px;
}

.mb25 {
  margin-bottom: 25px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb35 {
  margin-bottom: 35px;
}

.mb45 {
  margin-bottom: 45px;
}

.mt10 {
  margin-top: 10px;
}

.mt15 {
  margin-top: 15px;
}

.mt25 {
  margin-top: 25px;
}

.ml42 {
  margin-left: 42px;
}

.mb65 {
  margin-bottom: 65px;
}

.mt60 {
  margin-top: 60px;
}

.pt150 {
  padding-top: 150px;
}

.pb45 {
  padding-bottom: 30px;
}

.pb20 {
  padding-bottom: 20px;
}

.pb10 {
  padding-bottom: 10px;
}

.gradient-bg,
.modal-body .nws-button button,
.teacher-pic-content .teacher-img-content:after,
.course-details-category li:hover {
  background: #17d0cf;
  background: -moz-linear-gradient(left, #01a6fd 0%, #17d0cf 51%, #01a6fd 100%);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, #4eb3bf),
    color-stop(51%, #17d0cf),
    color-stop(100%, #4eb3bf)
  );
  background: -webkit-linear-gradient(
    left,
    #01a6fd 0%,
    #17d0cf 51%,
    #0071b0 100%
  );
  background: -o-linear-gradient(left, #01a6fd 0%, #17d0cf 51%, #01a6fd 100%);
  background: -ms-linear-gradient(left, #01a6fd 0%, #17d0cf 51%, #01a6fd 100%);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#01a6fd),
    color-stop(51%, #17d0cf),
    to(#01a6fd)
  );
  background: -webkit-linear-gradient(
    left,
    #01a6fd 0%,
    #17d0cf 51%,
    #01a6fd 100%
  );
  background: linear-gradient(to right, #01a6fd 0%, #17d0cf 51%, #01a6fd 100%);
  background-size: 200% auto;
  -webkit-transition: background 1s ease-out;
  -moz-transition: background 1s ease-out;
  -o-transition: background 1s ease-out;
  transition: background 1s ease-out;
}
.gradient-bg:hover,
.modal-body .nws-button button:hover,
.teacher-pic-content .teacher-img-content:hover:after,
.course-details-category li:hover {
  background: #01a6fd;
  background: -moz-linear-gradient(left, #17d0cf 0%, #01a6fd 51%, #17d0cf 100%);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, #4eb3bf),
    color-stop(51%, #01a6fd),
    color-stop(100%, #4eb3bf)
  );
  background: -webkit-linear-gradient(
    left,
    #17d0cf 0%,
    #01a6fd 51%,
    #0071b0 100%
  );
  background: -o-linear-gradient(left, #17d0cf 0%, #01a6fd 51%, #17d0cf 100%);
  background: -ms-linear-gradient(left, #17d0cf 0%, #01a6fd 51%, #17d0cf 100%);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#17d0cf),
    color-stop(51%, #01a6fd),
    to(#17d0cf)
  );
  background: -webkit-linear-gradient(
    left,
    #17d0cf 0%,
    #01a6fd 51%,
    #17d0cf 100%
  );
  background: linear-gradient(to right, #17d0cf 0%, #01a6fd 51%, #17d0cf 100%);
  background-size: 200% auto;
  background-position: 190px;
}

.text-gradiant {
  background: -moz-linear-gradient(left, #01a6fd 0%, #17d0cf 100%);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, #01a6fd),
    color-stop(100%, #17d0cf)
  );
  background: -webkit-linear-gradient(left, #01a6fd 0%, #17d0cf 100%);
  background: -o-linear-gradient(left, #01a6fd 0%, #17d0cf 100%);
  background: -ms-linear-gradient(left, #01a6fd 0%, #17d0cf 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.backgroud-style {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.whitish-overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.65);
}

.genius-btn {
  height: 50px;
  line-height: 52px;
  border-radius: 4px;
  display: table;
  padding: 0px 25px;
}
.genius-btn a {
  color: #fff;
  display: block;
  width: 100%;
}
.genius-btn a i {
  margin-left: 5px;
  color: #01a6fd;
}

.bold-font {
  font-weight: 700;
}

.section-title .subtitle {
  color: #a9a3a3;
  letter-spacing: 7px;
  position: relative;
}
.section-title .subtitle:before,
.section-title .subtitle:after {
  content: ".";
  font-size: 70px;
  left: -45px;
  pointer-events: none;
  position: absolute;
  text-shadow: rgba(0, 0, 0, 0) 0px 0px;
  top: -10px;
  line-height: 0;
  color: #17d0cf;
  text-shadow: 15px 0 #17d0cf;
}
.section-title .subtitle:after {
  right: -28px;
  left: inherit;
}

.section-title h2 {
  font-size: 55px;
  font-weight: 300;
  color: #333333;
}
.section-title h2 span {
  font-weight: 700;
}

.section-title-2 h2 {
  font-size: 38px;
  color: #333333;
  font-weight: 300;
  position: relative;
}
.section-title-2 h2 span {
  font-weight: 700;
}
.section-title-2 h2:after {
  position: absolute;
  content: "";
  bottom: -20px;
  height: 2px;
  width: 50px;
  left: 0;
  background-color: #17d0cf;
}

.widget-title {
  font-size: 25px;
  color: #333333;
  font-weight: 700;
  position: relative;
  margin-bottom: 50px;
}
.widget-title:after {
  position: absolute;
  content: "";
  bottom: -20px;
  height: 2px;
  width: 50px;
  left: 0;
  background-color: #17d0cf;
}

.breadcrumb-section {
  background-image: url(../img/banner/brt-1.jpg);
  padding-bottom: 355px;
}
.breadcrumb-section .blakish-overlay {
  opacity: 1;
  visibility: visible;
}
.breadcrumb-section .page-breadcrumb-content {
  position: absolute;
  top: 60%;
  -webkit-transform: translateY(-60%);
  -ms-transform: translateY(-60%);
  transform: translateY(-60%);
  left: 0;
  right: 0;
}
.breadcrumb-section .breadcrumb-head {
  font-size: 38px;
  color: #fff;
  font-weight: 300;
}
.breadcrumb-section .breadcrumb-head span {
  font-weight: 700;
}
.breadcrumb-section .breadcrumb {
  text-align: center;
  display: inherit;
  background-color: transparent;
}
.breadcrumb-section .breadcrumb-item {
  background-color: #17d0cf;
  color: #fff;
  padding: 5px 15px;
  font-size: 13px;
  font-weight: 700;
  border-radius: 4px;
}
.breadcrumb-section .breadcrumb-item::before {
  display: none;
}
.breadcrumb-section .breadcrumb-item.active {
  background-color: #3b3b3b;
  color: #7a7a7a;
}

/*---------------------------------------------------- */
/*Header Section*/
/*----------------------------------------------------*/
.main-menu-container {
  position: fixed;
  width: 100%;
  padding: 45px 0px;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  z-index: 9;
  background-color: #000;
}

.nav-menu {
  padding-top: 20px;
}
.nav-menu li a {
  color: #fff;
  text-transform: uppercase;
  font-size: 15px;
  padding: 7px 15px;
  font-weight: 500;
  position: relative;
  border-radius: 4px;
}
.nav-menu li a:after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: #17d0cf;
  background: -moz-linear-gradient(left, #01a6fd 0%, #17d0cf 51%, #01a6fd 100%);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, #4eb3bf),
    color-stop(51%, #17d0cf),
    color-stop(100%, #4eb3bf)
  );
  background: -webkit-linear-gradient(
    left,
    #01a6fd 0%,
    #17d0cf 51%,
    #0071b0 100%
  );
  background: -o-linear-gradient(left, #01a6fd 0%, #17d0cf 51%, #01a6fd 100%);
  background: -ms-linear-gradient(left, #01a6fd 0%, #17d0cf 51%, #01a6fd 100%);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#01a6fd),
    color-stop(51%, #17d0cf),
    to(#01a6fd)
  );
  background: -webkit-linear-gradient(
    left,
    #01a6fd 0%,
    #17d0cf 51%,
    #01a6fd 100%
  );
  background: linear-gradient(to right, #01a6fd 0%, #17d0cf 51%, #01a6fd 100%);
  background-size: 200% auto;
  left: 0;
  top: 0;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  border-radius: 4px;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
}
.nav-menu li a:hover:after {
  opacity: 1;
  visibility: visible;
}

.log-in {
  margin-top: 20px;
}
.log-in a {
  color: #fff;
  text-transform: uppercase;
  font-size: 15px;
  padding: 15px;
  font-weight: 700;
}

.cart-search {
  margin-top: 12px;
  margin-left: 10px;
}
.cart-search li {
  height: 35px;
  width: 35px;
  text-align: center;
  line-height: 30px;
  border-radius: 100%;
  color: #fff;
  margin-left: 10px;
  border: 2px solid #17d0cf;
  position: relative;
}
.cart-search li a {
  display: block;
  width: 100%;
}
.cart-search li button {
  background-color: transparent;
  border: none;
  color: #fff;
}

.select-lang {
  display: inline;
  position: relative;
  border-left: 1px solid;
  margin-left: 25px;
}
.select-lang select {
  margin-top: 12px;
  background-color: transparent;
  border: none;
  padding: 10px 20px;
  color: #17d0cf;
  -webkit-appearance: none;
  position: relative;
}
.select-lang:after {
  content: "\f078";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  top: 2px;
  right: 3px;
  font-size: 12px;
  font-weight: 700;
}

.main-menu-container.menu-bg-overlay {
  background: #000000;
  padding: 10px 0px;
}

.header-style-2.main-menu-container.menu-bg-overlay {
  background: #fff;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  padding-top: 0;
  padding-bottom: 25px;
  margin-top: -42px;
}

.header-top {
  border-bottom: 1px solid #eeeeee;
}
.header-top li {
  border-right: 1px solid #eeeeee;
  padding: 10px 20px;
}
.header-top li i {
  margin-right: 5px;
}
.header-top li select {
  border: none;
}

.header-top-text {
  padding: 10px 0px;
}

.header-style-2 {
  background-color: #fff;
  padding: 25px 0px;
  padding-top: 0;
}
.header-style-2 .main-menu {
  padding-top: 25px;
}
.header-style-2 .nav-menu li a {
  color: #616161;
}
.header-style-2 .nav-menu li a:hover {
  color: #fff;
}
.header-style-2 .cart-search li {
  border: none;
}
.header-style-2 .cart-search li button {
  border: none;
  line-height: 35px;
  padding: 0px 10px;
  border-radius: 100%;
  background-color: #999;
}
.header-style-2 .search-body {
  top: 70px;
  right: 0px;
}

.header_3 {
  padding: 30px 0px 70px;
  position: relative;
  z-index: 2;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}

.header-info {
  display: inline-block;
  margin-left: 50px;
}
.header-info li {
  margin-left: 45px;
  text-align: center;
  color: #fff;
  position: relative;
  height: 60px;
}
.header-info li i {
  font-size: 25px;
  line-height: 36px;
}
.header-info li:after {
  position: absolute;
  content: "";
  height: 60px;
  width: 1px;
  background-color: #eee;
  top: 0;
  right: -20px;
}
.header-info .info-text {
  color: #fff;
  font-size: 13px;
}

.mail-phone .info-icon {
  height: 55px;
  width: 55px;
  border-radius: 100%;
  line-height: 63px;
  text-align: center;
  float: left;
  background-color: #fff;
}
.mail-phone .info-icon i {
  font-size: 25px;
}

.mail-phone .info-id {
  font-size: 25px;
  font-weight: 700;
  color: #fff;
  display: block;
}

.mail-phone .info-content {
  display: inline-block;
  margin-left: 10px;
  text-align: left;
}

.nav-menu-4 {
  background-color: #fff;
  border-radius: 4px;
  padding: 25px 20px 25px 20px;
  position: absolute;
  line-height: 25px;
  left: 0;
  right: 0;
  max-width: 1170px;
  margin: 0 auto;
  bottom: -30px;
}
.nav-menu-4 .nav-menu li a {
  color: #333333;
}
.nav-menu-4 .nav-menu {
  padding-top: 0;
}
.nav-menu-4 select {
  font-size: 15px;
  margin-top: 0;
  padding: 0;
  width: 70px;
}
.nav-menu-4 .select-lang {
  border-left: none;
  margin-left: 0;
}
.nav-menu-4 .login-cart-lang ul {
  float: right;
}
.nav-menu-4 .login-cart-lang li {
  margin: 0 20px;
  position: relative;
}
.nav-menu-4 .login-cart-lang li:before {
  position: absolute;
  height: 40px;
  width: 1px;
  background-color: #eee;
  top: -8px;
  left: -20px;
  content: "";
}
.nav-menu-4 .login-cart-lang li i {
  font-size: 13px;
}
.nav-menu-4 .login-cart-lang .cart_search {
  height: 25px;
  width: 25px;
  background-color: #dddddd;
  color: #fff;
  border-radius: 100%;
  line-height: 22px;
  text-align: center;
}
.nav-menu-4 .login-cart-lang .login a {
  color: #17d0cf;
}
.nav-menu-4 .login-cart-lang button {
  background-color: transparent;
  border: none;
  color: #fff;
}

.search-body {
  top: 55px;
  right: 0px;
  opacity: 0;
  border-radius: 4px;
  width: 300px;
  height: auto;
  z-index: 999;
  padding: 10px;
  position: absolute;
  visibility: hidden;
  background-color: #fff;
  -webkit-box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.1);
}
.search-body:after {
  position: absolute;
  content: "";
  top: -10px;
  border-left: 20px solid transparent;
  border-right: 0px solid transparent;
  border-top: 20px solid #ffffff;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  right: 6px;
}
.search-body .search-form {
  width: 100%;
  height: auto;
  position: relative;
}
.search-body .search-form .search-input {
  width: 100%;
  height: 50px;
  padding: 0px 20px;
  color: #000;
  background-color: #f7f7f7;
  border: 1px solid #eee;
}
.search-body .search-form .search-input:focus {
  outline: none;
  border-color: #eee;
  color: #000;
}
.search-body .search-form .search-close {
  top: 1px;
  right: 1px;
  z-index: 1;
  width: 50px;
  height: 48px;
  cursor: pointer;
  line-height: 48px;
  text-align: center;
  position: absolute;
  background-color: #17d0cf;
  border: none;
  color: #000;
}

.modal {
  background-color: rgba(0, 0, 0, 0.8);
}

.search-open {
  opacity: 1;
  visibility: visible;
}

.modal-backdrop {
  z-index: 0;
}

.popup-logo {
  left: 0;
  right: 0;
  top: -30px;
  position: absolute;
  text-align: center;
}
.popup-logo img {
  border-radius: 4px;
}

.modal-dialog {
  margin: 12.75rem auto;
}

.modal-header {
  padding: 0;
  position: relative;
  background-image: url(../img/banner/pb.jpg);
}
.modal-header .gradient-bg,
.modal-header .modal-body .nws-button button,
.modal-body .nws-button .modal-header button,
.modal-header .teacher-pic-content .teacher-img-content:after,
.teacher-pic-content .modal-header .teacher-img-content:after,
.modal-header .course-details-category li:hover,
.course-details-category .modal-header li:hover {
  position: absolute;
  top: 0;
  border-radius: 4px;
  height: 100%;
  width: 100%;
  opacity: 0.9;
}

.popup-text {
  width: 100%;
  padding: 70px 0px 50px 0px;
  position: relative;
}
.popup-text h2 {
  color: #fff;
  font-weight: 300;
  font-size: 38px;
}
.popup-text h2 span {
  font-weight: 700;
}
.popup-text p {
  color: #fff;
}
.popup-text p span {
  font-weight: 700;
}

.facebook-login {
  background-color: #006dd9;
  height: 50px;
  line-height: 50px;
  text-transform: capitalize;
  border-radius: 4px;
  margin-bottom: 20px;
}
.facebook-login a {
  padding: 0;
}

.alt-text {
  font-weight: 700;
  position: relative;
  margin-bottom: 30px;
}
.alt-text a {
  color: #333333;
  font-size: 12px;
  padding: 0;
}
.alt-text:before {
  position: absolute;
  content: "";
  top: 10px;
  height: 1px;
  width: 40%;
  left: 0;
  background-color: #ccc;
}
.alt-text:after {
  position: absolute;
  content: "";
  top: 10px;
  height: 1px;
  width: 40%;
  right: 0;
  background-color: #ccc;
}

.log-in-icon {
  float: left;
  padding: 0 20px;
  border-right: 1px solid #a29c9c;
}

.modal-body {
  padding: 50px 65px;
}
.modal-body .contact_form {
  margin-bottom: 20px;
}
.modal-body .contact_form input,
.modal-body .contact_form .register-form-area select,
.register-form-area .modal-body .contact_form select,
.modal-body .contact_form .register-form-area textarea,
.register-form-area .modal-body .contact_form textarea {
  height: 50px;
  background-color: #eeeeee;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 10px;
  width: 100%;
  border: none;
}
.modal-body .nws-button button {
  height: 60px;
  width: 100%;
  border: none;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  font-size: 18px;
}
.modal-body p {
  margin-bottom: 5px;
}

.menu-item-has-children {
  position: relative;
}
.menu-item-has-children a {
  padding-bottom: 10px !important;
}
.menu-item-has-children:hover a:after {
  opacity: 1;
  visibility: visible;
}

.menu-item-has-children li {
  margin-bottom: 5px;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}

.menu-item-has-children li:hover {
  margin-left: 3px;
}

.sub-menu {
  width: 300px;
  display: table;
  -webkit-box-shadow: 0 5px 10px 0 rgba(83, 82, 82, 0.1);
  box-shadow: 0 5px 10px 0 rgba(83, 82, 82, 0.1);
  background-color: #fff;
  position: absolute;
  -webkit-transform: scaleY(0);
  -ms-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: center top 0;
  -ms-transform-origin: center top 0;
  transform-origin: center top 0;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  top: 30px;
  left: -115px;
  text-align: left;
  border-radius: 4px;
  padding: 15px !important;
}
.sub-menu li {
  position: relative;
  border-bottom: 1px solid #e8e8e8;
  padding: 10px 0px;
}
.sub-menu li a {
  text-transform: capitalize !important;
  color: #333;
  padding: 0;
}
.sub-menu li a:after {
  display: none;
}
.sub-menu li a:hover {
  color: #17d0cf !important;
}
.sub-menu li:last-child {
  border-bottom: none;
}
.sub-menu:before {
  position: absolute;
  content: "";
  top: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #01a6fd;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 20px;
}

.menu-item-has-children:hover .sub-menu {
  -webkit-transform: scaleY(1);
  -ms-transform: scaleY(1);
  transform: scaleY(1);
}

.header-style-2 .sub-menu li a:hover {
  color: #17d0cf;
}

.altranative-header {
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  display: none;
  padding: 30px;
  position: fixed;
  background-color: #151515;
}

.alt-menu-btn {
  max-width: 100%;
  cursor: pointer;
  overflow: hidden;
}

.alt-menu-btn .hamburger-menu {
  width: 30px;
  height: 30px;
  text-align: center;
  position: relative;
  display: inline-block;
}

.alt-menu-btn .hamburger-menu:before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #ffffff;
  font-size: 30px;
  content: "\f0c9";
  line-height: 30px;
  position: absolute;
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

button.alt-menu-btn.float-left {
  background-color: transparent;
  border: none;
}

.alt-menu-btn .hamburger-menu.open:before {
  content: "\f00d";
}

#menu-container .logo-area {
  height: auto;
  margin: -8px auto;
  text-align: center;
}

#menu-container .cart-Collapse {
  color: #ffffff;
  font-size: 16px;
}

#menu-container .menu-list {
  top: 88px;
  bottom: 0;
  left: -100%;
  height: 100%;
  width: 310px;
  z-index: 999;
  padding: 30px;
  position: fixed;
  min-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: #151515;
  padding-bottom: 200px;
}

#menu-container .menu-list .alt-search input[type="search"],
#menu-container
  .menu-list
  .alt-search
  .register-form-area
  select[type="search"],
.register-form-area
  #menu-container
  .menu-list
  .alt-search
  select[type="search"],
#menu-container
  .menu-list
  .alt-search
  .register-form-area
  textarea[type="search"],
.register-form-area
  #menu-container
  .menu-list
  .alt-search
  textarea[type="search"] {
  color: #ffffff;
  background-color: #000000;
  border: 1px solid rgba(255, 255, 255, 0.1);
  height: 50px;
  padding: 15px;
  width: 100%;
}

#menu-container .accordion .card {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none;
  border-radius: 0px;
}

#menu-container .accordion .card .card-header {
  padding: 0;
}

#menu-container .accordion .card .menu-link {
  width: 100%;
  display: block;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  padding: 10px 15px;
  border: none;
  background-color: #000;
  position: relative;
  color: #fff;
}

#menu-container .accordion .card button.menu-link:after {
  top: 50%;
  right: 15px;
  content: "\f078";
  position: absolute;
  font-weight: 700;
  font-family: "Font Awesome 5 Free";
  transform: rotate(0deg) translateY(-50%);
  -o-transform: rotate(0deg) translateY(-50%);
  -ms-transform: rotate(0deg) translateY(-50%);
  -moz-transform: rotate(0deg) translateY(-50%);
  -webkit-transform: rotate(0deg) translateY(-50%);
}

#menu-container .accordion .card.active,
#menu-container .accordion .card .submenu {
  background-color: #000000;
}

#menu-container .accordion .card:hover .menu-link,
#menu-container .accordion .card.active .menu-link {
  color: #fff;
  background-color: #000000;
  border: none;
}

#menu-container .accordion .card .submenu {
  padding: 0px 30px;
}

#menu-container .accordion .card .submenu li {
  padding: 5px 0px;
}

#menu-container .accordion .card .submenu li:hover,
#menu-container .accordion .card .submenu li.active a {
  color: #fff;
}

.cart-btn li {
  display: inline-block !important;
  margin: 0px 5px;
}

.cart-btn {
  margin-top: 10px;
}
.cart-btn li {
  font-size: 18px;
  color: #fff;
  margin: 0px 10px;
}

.header_3.full-width-menu {
  position: fixed;
  width: 100%;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  margin-top: -100px;
  z-index: 3;
}

.header_3 .nav-menu-4 .nav-menu a {
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
.header_3 .nav-menu-4 .nav-menu a:hover {
  color: #00eab2;
}

.modal-body .log-in-text {
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
}

.modal-body .log-in-icon i {
  font-size: 20px;
  color: #fff;
}

/*---------------------------------------------------- */
/*slider Section*/
/*----------------------------------------------------*/
.slider-text {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.slider-text .section-title h2 {
  font-size: 90px;
  color: #fff;
  font-weight: 100;
}

#slider-item:hover {
  visibility: visible;
}

#slider-item .genius-btn {
  border: 2px solid #17d0cf;
  margin-top: 30px;
}

#slider-item .owl-dots {
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
}
#slider-item .owl-dots .owl-dot.active {
  height: 45px;
  width: 3px;
  border-radius: 4px;
  background-color: #fff;
}
#slider-item .owl-dots .owl-dot {
  height: 20px;
  background-color: #585656;
  width: 3px;
}

#slider-item .owl-nav {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 95%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

#slider-item .owl-next {
  float: right;
}

#slider-item .scound-slide {
  font-size: 70px;
}

#slider-item .search-course {
  max-width: 700px;
  margin: 0 auto;
}

#slider-item .owl-item .layer-1-2 {
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  opacity: 0;
  -webkit-transition: all 1s ease-in-out 0.3s;
  -o-transition: all 1s ease-in-out 0.3s;
  transition: all 1s ease-in-out 0.3s;
}

#slider-item .owl-item .layer-1-1 {
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  opacity: 0;
  -webkit-transition: all 1s ease-in-out 1s;
  -o-transition: all 1s ease-in-out 1s;
  transition: all 1s ease-in-out 1s;
}

#slider-item .owl-item .layer-1-3 {
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
  opacity: 0;
  -webkit-transition: all 1s ease-in-out 1.5s;
  -o-transition: all 1s ease-in-out 1.5s;
  transition: all 1s ease-in-out 1.5s;
}

#slider-item .owl-item .layer-1-4 {
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  opacity: 0;
  -webkit-transition: all 1s ease-in-out 2s;
  -o-transition: all 1s ease-in-out 2s;
  transition: all 1s ease-in-out 2s;
}

#slider-item .owl-item .layer-1-5 {
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  opacity: 0;
  -webkit-transition: all 1s ease-in-out 2.5s;
  -o-transition: all 1s ease-in-out 2.5s;
  transition: all 1s ease-in-out 2.5s;
}

#slider-item .owl-item .layer-1-6 {
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  opacity: 0;
  -webkit-transition: all 1s ease-in-out 3s;
  -o-transition: all 1s ease-in-out 3s;
  transition: all 1s ease-in-out 3s;
}

#slider-item .owl-item.active .layer-1-2,
#slider-item .owl-item.active .layer-1-1,
#slider-item .owl-item.active .layer-1-3,
#slider-item .owl-item.active .layer-1-4,
#slider-item .owl-item.active .layer-1-5,
#slider-item .owl-item.active .layer-1-6 {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

#slider-item .owl-item.active .slider-images img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: 10s;
  -o-transition: 10s;
  transition: 10s;
}

#slider-item .slider-course-category {
  max-width: 700px;
  margin: 0 auto;
  margin-top: 25px;
}
#slider-item .slider-course-category li {
  color: #fff;
  font-weight: 700;
  margin: 0px 10px;
  position: relative;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
#slider-item .slider-course-category li:after {
  position: absolute;
  content: "";
  height: 10px;
  width: 2px;
  top: 6px;
  right: -13px;
  background-color: #8e8e8e;
}
#slider-item .slider-course-category li:last-child:after {
  display: none;
}
#slider-item .slider-course-category li:hover {
  color: #17d0cf;
}

#slider-item .third-slide {
  font-size: 60px;
}

#slider-item .secoud-title {
  font-size: 70px;
}

.coming-countdown {
  margin-bottom: 30px;
  text-align: center;
}

.coming-countdown li {
  width: 100px;
  height: auto;
  padding: 15px 5px;
  margin-right: 30px;
  text-align: center;
  display: inline-block;
  color: #fff;
  border: 2px solid #17d0cf;
  border-radius: 8px;
}

.coming-countdown li:last-child {
  margin-right: 0px;
}

.coming-countdown .number {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 5px;
}

.coming-countdown span {
  display: block;
  color: #ffffff;
  position: relative;
  text-transform: uppercase;
}

.coming-countdown span:before {
  left: 0;
  right: 0;
  top: -20px;
  width: 50px;
  height: 3px;
  margin: 0 auto;
  position: absolute;
  background: #ffffff;
  border-radius: 45px;
}

.slider-bg-1,
.slider-bg-2,
.slider-bg-3,
.slider-bg-4,
.slider-bg-5 {
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.slider-bg-1 {
  background-image: url(../img/banner/s-1.jpg);
}

.slider-bg-2 {
  background-image: url(../img/banner/s-4.jpg);
}

.slider-bg-3 {
  background-image: url(../img/banner/s-3.jpg);
}

.slider-bg-4 {
  background-image: url(../img/banner/s-4.jpg);
}

.slider-bg-5 {
  background-image: url(../img/banner/sd-1.jpg);
}
.slider-bg-5 .slider-text {
  max-width: 1170px;
  margin: 0 auto;
}

#course-btn .genius-btn {
  margin: 0 auto;
}

/*---------------------------------------------------- */
/*Search Course*/
/*----------------------------------------------------*/
.search-course-section {
  padding: 125px 0px 125px 0px;
}

.search-course input,
.search-course .register-form-area select,
.register-form-area .search-course select,
.search-course .register-form-area textarea,
.register-form-area .search-course textarea,
.subs-form input,
.subs-form .register-form-area select,
.register-form-area .subs-form select,
.subs-form .register-form-area textarea,
.register-form-area .subs-form textarea {
  width: 100%;
  height: 60px;
  background-color: #f9f9f9;
  border: none;
  padding: 0px 20px;
  border-radius: 5px;
}

.search-course .nws-button,
.subs-form .nws-button {
  top: 0;
  right: 0;
  height: 60px;
  width: 195px;
  line-height: 60px;
  position: absolute;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.search-course .nws-button button,
.subs-form .nws-button button {
  background-color: transparent;
  border: none;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
}

.counter-icon {
  float: left;
  width: 60px;
  height: 45px;
  line-height: 40px;
  margin-right: 15px;
  text-align: center;
  border-right: 1px solid #989898;
}
.counter-icon i {
  font-size: 38px;
}

.counter-number {
  display: inline-block;
  overflow: hidden;
}

.counter-number p {
  font-size: 14px;
  font-weight: 700;
  font-color: #989898;
}

.counter-number .counter-count,
.counter-number span {
  font-size: 30px;
  color: #333333;
  line-height: 0.9;
}

.counter-number span {
  font-weight: 700;
}

.copy-right-menu-item li {
  color: #989898;
  margin-left: 30px;
}

.copy-right-menu {
  padding: 45px 0px;
  border-top: 1px solid #ddd6d6;
}

.home-secound-course-search {
  background-image: url(../img/banner/hb-2.jpg);
  padding-bottom: 0;
}
.home-secound-course-search .search-app {
  padding-top: 50px;
}
.home-secound-course-search .search-app-content {
  margin-left: 40px;
}
.home-secound-course-search .search-course input,
.home-secound-course-search .search-course .register-form-area select,
.register-form-area .home-secound-course-search .search-course select,
.home-secound-course-search .search-course .register-form-area textarea,
.register-form-area .home-secound-course-search .search-course textarea {
  background: #4273e1;
  background: -moz-linear-gradient(left, #01a6fd 0%, #4273e1 51%, #01a6fd 100%);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, #4eb3bf),
    color-stop(51%, #4273e1),
    color-stop(100%, #4eb3bf)
  );
  background: -webkit-linear-gradient(
    left,
    #01a6fd 0%,
    #4273e1 51%,
    #0071b0 100%
  );
  background: -o-linear-gradient(left, #01a6fd 0%, #4273e1 51%, #01a6fd 100%);
  background: -ms-linear-gradient(left, #01a6fd 0%, #4273e1 51%, #01a6fd 100%);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#01a6fd),
    color-stop(51%, #4273e1),
    to(#01a6fd)
  );
  background: -webkit-linear-gradient(
    left,
    #01a6fd 0%,
    #4273e1 51%,
    #01a6fd 100%
  );
  background: linear-gradient(to right, #01a6fd 0%, #4273e1 51%, #01a6fd 100%);
  background-size: 200% auto;
  color: #fff;
}
.home-secound-course-search .search-course input::-webkit-input-placeholder,
.home-secound-course-search
  .search-course
  .register-form-area
  select::-webkit-input-placeholder,
.register-form-area
  .home-secound-course-search
  .search-course
  select::-webkit-input-placeholder,
.home-secound-course-search
  .search-course
  .register-form-area
  textarea::-webkit-input-placeholder,
.register-form-area
  .home-secound-course-search
  .search-course
  textarea::-webkit-input-placeholder {
  color: #fff;
}
.home-secound-course-search .counter-number p {
  color: #fff;
}
.home-secound-course-search .counter-count,
.home-secound-course-search .counter-number span,
.counter-number .home-secound-course-search span {
  color: #fff;
}
.home-secound-course-search .section-title span,
.home-secound-course-search .section-title h2 {
  color: #fff;
}
.home-secound-course-search .search-app .search-app-content h2 {
  font-size: 35px;
}
.home-secound-course-search .app-details-content p {
  font-size: 20px;
  color: #fff;
}
.home-secound-course-search .about-list li {
  color: #17d0cf;
  margin-left: 5px;
}
.home-secound-course-search .about-list li:before {
  width: 10px;
  height: 10px;
  top: 7px;
  background-color: #5196c9;
}
.home-secound-course-search .genius-btn {
  width: 190px;
}

.app-stor {
  display: inline-block;
}
.app-stor li {
  font-size: 22px;
  color: #fff;
  margin-right: 25px;
  position: relative;
}
.app-stor li:after {
  position: absolute;
  content: "";
  height: 20px;
  width: 1px;
  top: 4px;
  right: -14px;
  background-color: #9ad1f6;
}
.app-stor li:last-child:after {
  display: none;
}

.search-course-secound {
  background-color: #333333;
  padding: 20px 0px 10px !important;
  max-width: 1170px;
  margin: 0 auto;
  border-radius: 5px;
  position: relative;
  z-index: 2;
  top: -40px;
}
.search-course-secound .counter-number .counter-count,
.search-course-secound .counter-number span,
.search-course-secound .counter-number span {
  color: #fff;
}
.search-course-secound .counter-number p {
  color: #17d0cf;
  margin: 0;
}

.search-course-third {
  padding: 0;
}
.search-course-third .version-four {
  padding: 40px 35px 30px;
}
.search-course-third .counter-number p {
  margin-bottom: 0;
}
.search-course-third .counter-icon-number {
  position: relative;
}
.search-course-third .counter-icon-number:before,
.search-course-third .counter-icon-number:after {
  position: absolute;
  content: "";
  bottom: -30px;
  background-color: #eee;
  width: 110%;
  height: 7px;
  left: -20px;
  -webkit-transition: 0.5s all ease-in-out;
  -o-transition: 0.5s all ease-in-out;
  transition: 0.5s all ease-in-out;
}
.search-course-third .counter-icon-number:after {
  background-color: #17d0cf;
  width: 0;
}
.search-course-third .counter-icon-number:hover::after {
  width: 110%;
}

.home-third-course-search {
  background-color: #f7f7f7;
  padding-bottom: 0;
}
.home-third-course-search .search-course input,
.home-third-course-search .search-course .register-form-area select,
.register-form-area .home-third-course-search .search-course select,
.home-third-course-search .search-course .register-form-area textarea,
.register-form-area .home-third-course-search .search-course textarea {
  background-color: #fff;
}
.home-third-course-search .app-stor li {
  color: #777777;
}
.home-third-course-search .app-stor li:after {
  background-color: #777777;
}
.home-third-course-search .section-title h2 {
  font-size: 42px;
}

#search-course-2 .search-app {
  margin-top: 50px;
}

/*---------------------------------------------------- */
/*popular Course*/
/*----------------------------------------------------*/
.popular-course-section {
  padding-bottom: 125px;
}
.popular-course-section .section-title .subtitle {
  margin-left: 42px;
}

.course-meta {
  display: inline-block;
  width: 100%;
}
.course-meta span {
  float: left;
  font-size: 14px;
  margin-right: 24px;
  position: relative;
}
.course-meta span:after {
  top: 2px;
  width: 1px;
  content: "";
  height: 15px;
  right: -14px;
  position: absolute;
  background-color: #989898;
}
.course-meta span:last-child:after {
  display: none;
}
.course-meta .course-category,
.latest-events .course-meta .course-author {
  color: #17d0cf;
}
.course-meta .course-author {
  color: #333333;
}
.course-meta .course-rate li {
  color: #ffc926;
}

.course-title:after {
  left: 0;
  content: "";
  height: 3px;
  width: 50px;
  bottom: 25px;
  position: absolute;
}

.course-title h3 {
  color: #333333;
  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
.course-title h3 span {
  top: -6px;
  color: #fff;
  padding: 5px;
  font-size: 11px;
  border-radius: 3px;
  position: relative;
  background-color: #ff5c26;
}

.course-viewer li {
  color: #989898;
  font-size: 14px;
  padding: 5px 15px;
  border-radius: 3px;
  background-color: #f7f7f7;
}

.course-price {
  top: 20px;
  left: 20px;
  font-weight: 700;
  padding: 5px 15px;
  border-radius: 4px;
  position: absolute;
}
.course-price span {
  color: #fff;
}

.course-details-btn {
  top: 25px;
  right: 5px;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
.course-details-btn a {
  font-size: 12px;
  color: #fff;
  font-weight: 700;
}

.course-item-pic-text:hover .course-details-btn {
  right: 20px;
  opacity: 1;
  visibility: visible;
}

.owl-nav {
  right: 0;
  top: -90px;
  position: absolute;
}
.owl-nav .owl-prev,
.owl-nav .owl-next {
  float: left;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  margin: 0 5px;
  border-radius: 4px;
  background-color: #f7f7f7;
}

.popular-three {
  background-color: #f7f7f7;
  padding: 100px 0px 100px;
}
.popular-three .course-viewer li {
  background-color: #fff;
}
.popular-three .owl-nav .owl-prev,
.popular-three .owl-nav .owl-next,
.popular-three .owl-nav .owl-next {
  background-color: #fff;
}

.course-pic {
  overflow: hidden;
  border-radius: 4px;
}
.course-pic img {
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}

.course-item-pic-text:hover img {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}

/*---------------------------------------------------- */
/*About Us*/
/*----------------------------------------------------*/
.about-us-section {
  background-color: #f7f7f7;
  padding: 125px 0px;
  overflow: visible;
}

.about-resigter-form {
  z-index: 2;
  padding: 30px;
  max-width: 360px;
  margin-top: 120px;
}

.register-content {
  position: relative;
}

.register-fomr-title h3 {
  font-size: 35px;
  color: #fff;
}
.register-fomr-title h3 span {
  font-weight: 300;
}

.register-fomr-title p {
  font-size: 14px;
  color: #fff;
}

.register-form-area input,
.register-form-area select,
.register-form-area textarea {
  height: 42px;
  width: 100%;
  background-color: #fff;
  padding: 0 15px;
  border-radius: 4px;
  margin-bottom: 5px;
  border: none;
}

.register-form-area textarea {
  height: 100px;
  padding-top: 15px;
}

.register-form-area button {
  height: 60px;
  width: 100%;
  font-weight: 700;
  color: #fff;
  border: none;
  text-transform: uppercase;
  background-color: #ff5b10;
}

.bg-mockup {
  position: absolute;
  top: 10px;
  left: -6px;
  z-index: 1;
}

.about-us-text {
  padding-top: 35px;
}
.about-us-text .section-title {
  margin-bottom: 60px;
}
.about-us-text .section-title p {
  font-size: 22px;
  font-weight: 500;
  color: #333333;
  margin-top: 20px;
}
.about-us-text .section-title:after {
  position: absolute;
  content: "";
  bottom: -30px;
  height: 2px;
  width: 50px;
  background-color: #17d0cf;
}

.about-content-text p {
  font-size: 18px;
  color: #333333;
}

.about-list li {
  font-weight: 500;
  color: #333333;
  margin-bottom: 8px;
  padding-left: 25px;
  position: relative;
}
.about-list li:before {
  left: 0;
  content: "";
  width: 15px;
  height: 15px;
  top: 2px;
  border-radius: 100%;
  position: absolute;
  background-color: #17d0cf;
}

.about-btn .genius-btn {
  display: inline-block;
  margin-right: 25px;
}

.home-secound .about-resigter-form {
  background-image: url(../img/about/abt.jpg);
  max-width: 422px;
  margin-top: 46px;
  padding: 25px;
  margin-left: 27px;
  border-radius: 24px;
  position: relative;
  z-index: 2;
  -webkit-box-shadow: 10px 10px 30px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 10px 10px 30px 10px rgba(0, 0, 0, 0.1);
}
.home-secound .about-resigter-form:after {
  content: "";
  position: absolute;
  height: 80%;
  top: 50%;
  width: 30px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 5px;
  left: -30px;
  z-index: -1;
  background: #17d0cf;
  background: -moz-linear-gradient(left, #01a6fd 0%, #17d0cf 51%, #01a6fd 100%);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, #4eb3bf),
    color-stop(51%, #17d0cf),
    color-stop(100%, #4eb3bf)
  );
  background: -webkit-linear-gradient(
    left,
    #01a6fd 0%,
    #17d0cf 51%,
    #0071b0 100%
  );
  background: -o-linear-gradient(left, #01a6fd 0%, #17d0cf 51%, #01a6fd 100%);
  background: -ms-linear-gradient(left, #01a6fd 0%, #17d0cf 51%, #01a6fd 100%);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#01a6fd),
    color-stop(51%, #17d0cf),
    to(#01a6fd)
  );
  background: -webkit-linear-gradient(
    left,
    #01a6fd 0%,
    #17d0cf 51%,
    #01a6fd 100%
  );
  background: linear-gradient(to right, #01a6fd 0%, #17d0cf 51%, #01a6fd 100%);
  background-size: 200% auto;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.home-secound .register-form-area button {
  background: #17d0cf;
  background: -moz-linear-gradient(left, #01a6fd 0%, #17d0cf 51%, #01a6fd 100%);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, #4eb3bf),
    color-stop(51%, #17d0cf),
    color-stop(100%, #4eb3bf)
  );
  background: -webkit-linear-gradient(
    left,
    #01a6fd 0%,
    #17d0cf 51%,
    #0071b0 100%
  );
  background: -o-linear-gradient(left, #01a6fd 0%, #17d0cf 51%, #01a6fd 100%);
  background: -ms-linear-gradient(left, #01a6fd 0%, #17d0cf 51%, #01a6fd 100%);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#01a6fd),
    color-stop(51%, #17d0cf),
    to(#01a6fd)
  );
  background: -webkit-linear-gradient(
    left,
    #01a6fd 0%,
    #17d0cf 51%,
    #01a6fd 100%
  );
  background: linear-gradient(to right, #01a6fd 0%, #17d0cf 51%, #01a6fd 100%);
  background-size: 200% auto;
}

.home-secound .bg-mockup {
  display: none;
}

.home-secound .register-form-area input,
.home-secound .register-form-area select,
.home-secound .register-form-area textarea,
.home-secound .register-form-area select,
.home-secound .register-form-area textarea {
  background-color: #ffffffc7;
}

.home-third {
  background-color: #fff;
}

.about-page-section {
  padding: 80px 0px;
}

.about-gallery {
  display: inline-block;
  margin-bottom: 20px;
}

.about-gallery-img {
  float: left;
  margin-bottom: 15px;
}

.grid-1 {
  width: 66.5%;
  float: left;
}

.grid-2 {
  width: 31.5%;
  float: left;
  margin-left: 12px;
}

.about-text-item .section-title-2 {
  margin-bottom: 65px;
}
.about-text-item .section-title-2 h2:after {
  bottom: -30px;
}

.about-text-item p {
  font-weight: 300;
  font-size: 20px;
  color: #333333;
  margin-bottom: 25px;
}

.about-teacher-2 .section-title-2 h2 {
  font-size: 28px;
}
.about-teacher-2 .section-title-2 h2:after {
  display: none;
}
.about-teacher-2 .section-title-2 h2 b {
  color: #17d0cf;
  font-size: 14px;
  font-weight: 700;
}

.about-course-categori {
  padding-top: 50px;
}
.about-course-categori .category-slide {
  margin-top: 40px;
}

/*---------------------------------------------------- */
/*testimonial section*/
/*----------------------------------------------------*/
.why-choose-us-section .jarallax {
  background-image: url(../img/banner/tesit-b-1.jpg);
  padding: 140px 0px 115px 0px;
}

.why-choose-us-section .section-title {
  margin-bottom: 50px;
}
.why-choose-us-section .section-title h2 {
  color: #fff;
}
.why-choose-us-section .section-title span {
  color: #fff;
}

.service-slide {
  margin-bottom: 50px;
}

.service-icon {
  margin-right: 15px;
}
.service-icon i {
  font-size: 50px;
}

.service-text {
  overflow: hidden;
}
.service-text h3 {
  font-size: 22px;
  color: #fff;
}
.service-text p {
  color: #88c3ef;
}

.owl-dots {
  text-align: center;
  margin-top: 30px;
}
.owl-dots .owl-dot {
  height: 5px;
  width: 20px;
  display: inline-block;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  margin: 0 7px;
  border-radius: 10px;
  background-color: #4175a4;
}
.owl-dots .owl-dot.active {
  width: 40px;
  background-color: #fff;
}

.testimonial-slide .section-title-2 h2 {
  color: #fff;
}

.testimonial-slide .student-qoute {
  border: 2px solid #3080b6;
  padding: 30px;
  border-radius: 5px;
  position: relative;
}
.testimonial-slide .student-qoute:after {
  position: absolute;
  content: "\f10d";
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
  color: #3080b6;
  font-size: 40px;
  right: 35px;
  padding: 5px 20px;
  bottom: -30px;
  background-color: #125693;
}
.testimonial-slide .student-qoute p {
  font-style: italic;
  color: #fff;
  font-size: 18px;
  font-weight: 300;
}
.testimonial-slide .student-qoute p b {
  font-weight: 700;
}

.testimonial-slide .student-name-designation span {
  font-size: 13px;
  color: #17d0cf;
  margin-right: 25px;
  position: relative;
}
.testimonial-slide .student-name-designation span:before {
  top: 4px;
  width: 1px;
  content: "";
  height: 10px;
  right: -14px;
  position: absolute;
  background-color: #17d0cf;
}
.testimonial-slide .student-name-designation span:last-child::before {
  display: none;
}

.testimonial-slide .student-name-designation .st-designation {
  font-weight: 300;
}

.testimonial-slide-area .owl-stage-outer {
  padding-bottom: 30px;
}

.testimonial-slide-area .owl-prev,
.testimonial-slide-area .owl-next {
  background-color: #3080b6;
  color: #f7f7f7;
}

.secound-testimoinial-section {
  max-width: 880px;
  margin: 0 auto;
  padding: 10px 0px 90px 0px;
}
.secound-testimoinial-section .student-qoute {
  padding: 0;
  border: none;
}
.secound-testimoinial-section .student-qoute p {
  font-size: 25px;
  color: #333333;
  margin-bottom: 30px;
}
.secound-testimoinial-section .student-qoute:after {
  display: none;
}
.secound-testimoinial-section .owl-dot {
  background-color: #f5f5f5;
}
.secound-testimoinial-section .owl-dot.active {
  background-color: #989898;
}
.secound-testimoinial-section .student-name-designation span {
  color: #989898;
}
.secound-testimoinial-section .student-name-designation span:before {
  background-color: #989898;
}
.secound-testimoinial-section .student-name-designation .st-name {
  color: #333333;
}

.testimonial_2_section {
  padding-top: 100px;
}
.testimonial_2_section .section-title-2 h2,
.testimonial_2_section .section-title-2 span {
  color: #333333;
}
.testimonial_2_section .student-qoute {
  border: 2px solid #f7f7f7;
}
.testimonial_2_section .student-qoute p {
  color: #8c8c8c;
}
.testimonial_2_section .student-qoute p b {
  color: #333333;
}
.testimonial_2_section .student-qoute:after {
  background-color: #fff;
  color: #8c8c8c;
}
.testimonial_2_section .owl-next,
.testimonial_2_section .owl-prev,
.testimonial_2_section .owl-nav .owl-next,
.owl-nav .testimonial_2_section .owl-next,
.testimonial_2_section .testimonial-slide-area .owl-next,
.testimonial-slide-area .testimonial_2_section .owl-next {
  background-color: #f7f7f7;
  color: #999999;
}

.third-testimoinial-section {
  padding: 100px 0px 40px;
}
.third-testimoinial-section .section-title-2 h2 {
  color: #333333;
}

/*---------------------------------------------------- */
/*latest section*/
/*----------------------------------------------------*/
.latest-area-section {
  padding: 125px 0px 55px 0px;
}

.latest-news-thumbnile {
  height: 120px;
  width: 120px;
  float: left;
  margin-right: 20px;
  overflow: hidden;
}
.latest-news-thumbnile .hover-search {
  position: absolute;
  top: 50%;
  left: 0;
  color: #fff;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  right: 0;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  font-size: 20px;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
}

.blakish-overlay {
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  position: absolute;
  visibility: hidden;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  background-color: rgba(0, 0, 0, 0.65);
}

.latest-news-area {
  display: inline-block;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #ccc;
  width: 100%;
  max-width: 335px;
}
.latest-news-area:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.latest-news-area .date-meta {
  color: #989898;
  font-size: 14px;
  margin-bottom: 10px;
}
.latest-news-area:hover .blakish-overlay {
  opacity: 1;
  visibility: visible;
}
.latest-news-area:hover .hover-search {
  opacity: 1;
  visibility: visible;
}

.latest-title {
  font-size: 18px;
  color: #333333;
  margin-bottom: 10px;
}

.view-all-btn {
  color: #333333;
}
.view-all-btn i {
  color: #17d0cf;
  margin-left: 5px;
}

.gradient-bdr {
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 10px;
  -webkit-transform: scale(1.06);
  -ms-transform: scale(1.06);
  transform: scale(1.06);
  background: -o-linear-gradient(69deg, #10abff, #1beabd);
  background: linear-gradient(21deg, #10abff, #1beabd);
  background: -webkit-linear-gradient(69deg, #10abff, #1beabd);
}

.events-date {
  float: left;
  height: 84px;
  width: 95px;
  font-size: 13px;
  font-weight: 500;
  border-radius: 10px;
  margin-right: 20px;
  background-color: #fff;
}
.events-date span {
  font-size: 50px;
  padding-top: 8px;
  color: #333333;
  line-height: 1;
  display: block;
}

.latest-events {
  display: inline-block;
  max-width: 340px;
  margin-bottom: 30px;
}
.latest-events .course-meta .course-category,
.latest-events .course-meta .course-author {
  font-weight: 500;
  color: #989898;
}

.event-text {
  overflow: hidden;
}

.video-play-btn {
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 100%;
  color: #fff;
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  margin: 0 auto;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.video-play-btn a {
  display: block;
  width: 100%;
}

.sponsor-section {
  padding: 30px 0px 125px 0px;
}

.sponsor-pic {
  position: relative;
  width: 180px;
  margin: 0 auto;
}
.sponsor-pic:after {
  position: absolute;
  top: 20px;
  content: "";
  height: 80px;
  background-color: #cccccc;
  right: 0;
  width: 1px;
}

.sponsor-1 .owl-stage-outer {
  padding: 0 10px;
}

.sponsor-1 .owl-dots {
  display: none !important;
}

.sponsor-2 {
  padding: 60px 0px;
}
.sponsor-2 .sponsor-pic:after {
  display: none;
}
.sponsor-2 .owl-nav {
  display: none;
}
.sponsor-2 .owl-dots .owl-dot {
  background-color: #f5f5f5;
}
.sponsor-2 .owl-dots .owl-dot.active {
  background-color: #cccccc;
}

.home-page-three {
  padding: 40px 0px 125px;
}

/*---------------------------------------------------- */
/*Best course section*/
/*----------------------------------------------------*/
.best-course-section {
  background-color: #f7f7f7;
  padding: 125px 0px 125px 0px;
}

.best-course-pic-text {
  border-radius: 4px;
  padding-top: 30px;
}
.best-course-pic-text .course-title h3 {
  font-size: 18px;
  line-height: 25px;
}
.best-course-pic-text .course-title:after {
  display: none;
}
.best-course-pic-text .best-course-text {
  background-color: #fff;
  border-radius: 4px;
  padding: 20px 25px 30px 25px;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
.best-course-pic-text .best-course-text .course-meta span {
  font-weight: 400;
  color: #777777;
  -webkit-font-smoothing: antialiased;
}
.best-course-pic-text .best-course-pic {
  overflow: hidden;
  border-radius: 4px;
}
.best-course-pic-text .course-price {
  bottom: 20px;
  z-index: 1;
  top: inherit;
}
.best-course-pic-text .course-rate {
  position: absolute;
  right: 25px;
  bottom: 25px;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
.best-course-pic-text .course-rate li {
  font-size: 14px;
}
.best-course-pic-text .course-details-btn {
  top: inherit;
  bottom: 25px;
  right: 5px;
}
.best-course-pic-text:hover .course-details-btn {
  opacity: 1;
  right: 25px;
  visibility: visible;
  z-index: 1;
}
.best-course-pic-text:hover .course-rate {
  opacity: 0;
}
.best-course-pic-text:hover .blakish-overlay {
  opacity: 1;
  visibility: visible;
  z-index: 0;
}
.best-course-pic-text:hover .best-course-text {
  -webkit-box-shadow: 10px 10px 20px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 10px 10px 20px 1px rgba(0, 0, 0, 0.1);
}

.course-rate li {
  color: #ffc926;
}

.trend-badge-2 {
  top: -18px;
  left: -50px;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  position: absolute;
  padding: 30px 35px 5px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  background-color: #ff5a00;
}
.trend-badge-2 span {
  display: block;
}

.couse-pagination li {
  font-size: 18px;
  color: #bababa;
  margin: 0 5px;
}

.couse-pagination li.active {
  color: #333333;
  font-weight: 700;
}

.couse-pagination .pg-text {
  font-size: 13px;
}

.best-course-v2 {
  background-color: #fff;
  padding-bottom: 80px;
}
.best-course-v2 .best-course-pic-text .best-course-text {
  background-color: #f7f7f7;
}

.course-search-filter {
  display: inline-block;
  width: 100%;
}

.course-button ul.product-tab li.active:after {
  display: none;
}

.course-button ul.product-tab li:before {
  content: "";
  position: absolute;
  top: -15px;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #01a6fd;
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
  left: 0;
  width: 20px;
  right: 0;
  opacity: 0;
  margin: 0 auto;
}

.course-button ul.product-tab li.active:before {
  opacity: 1;
}

.course-button ul.product-tab li.active .category-icon-title:after {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.course-button ul.product-tab li.active .category-icon i {
  color: #fff;
  -webkit-text-fill-color: inherit;
}

.course-button ul.product-tab li.active .category-title h4 {
  color: #fff;
}

.course-button .category-title h4 {
  text-transform: capitalize;
  font-size: 16px;
}

.course-button ul.product-tab li {
  margin: 0;
}
.course-button ul.product-tab li .category-icon-title {
  width: 180px;
  height: 180px;
  padding: 20px;
  overflow: hidden;
}

.button-tab .owl-stage-outer {
  padding: 20px 0px;
}

.button-tab .owl-prev,
.button-tab .owl-nav .owl-next,
.owl-nav .button-tab .owl-next,
.button-tab .testimonial-slide-area .owl-next,
.testimonial-slide-area .button-tab .owl-next {
  position: absolute;
  left: 0;
}

.course-button {
  background-color: #fff;
}

.one-page-category {
  padding-top: 0;
}
.one-page-category .category-slide {
  margin-top: 0;
}
.one-page-category .category-slide .owl-nav {
  top: 46%;
  width: 108%;
}

/*---------------------------------------------------- */
/*Teacher  section*/
/*----------------------------------------------------*/
.course-teacher-section .jarallax {
  background-image: url(../img/banner/teacher-b.jpg);
  padding: 125px 0px 125px 0px;
}

.course-teacher-section .section-title {
  margin-bottom: 70px;
}
.course-teacher-section .section-title h2 {
  color: #fff;
}
.course-teacher-section .section-title span {
  color: #fff;
}

.course-teacher-section .genius-btn {
  margin: 0 auto;
}

.teacher-img-content {
  background-color: #0a4a78;
  display: inline-block;
  padding: 20px;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 50px;
}
.teacher-img-content .teacher-social-name {
  float: left;
  max-width: 65px;
}
.teacher-img-content .teacher-social-name ul {
  margin-bottom: 10px !important;
}
.teacher-img-content .teacher-social-name li {
  height: 35px;
  width: 35px;
  border-radius: 100%;
  text-align: center;
  color: #adb8bf;
  line-height: 35px;
  margin-bottom: 5px;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  background-color: #608297;
}
.teacher-img-content .teacher-social-name li:hover {
  background-color: #fff;
  color: #176299;
}
.teacher-img-content .teacher-social-name .teacher-name span {
  font-weight: 700;
  color: #fff;
  font-size: 18px;
}
.teacher-img-content .teacher-img {
  height: 150px;
  width: 150px;
  display: inline-block;
  position: relative;
  right: -35px;
  top: -35px;
}
.teacher-img-content .teacher-img img {
  border-radius: 4px;
}
.teacher-img-content .teacher-img:after {
  content: "";
  height: 2px;
  width: 50px;
  left: -25px;
  bottom: 30px;
  position: absolute;
  background-color: #17d0cf;
}
.teacher-img-content .teacher-img .course-price {
  font-weight: 400;
  top: auto;
  bottom: 20px;
  font-size: 12px;
  left: 45px;
  opacity: 0;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  visibility: hidden;
}
.teacher-img-content .teacher-category {
  position: relative;
  top: -5px;
}
.teacher-img-content .teacher-category .st-name {
  font-size: 13px;
  color: #fff;
}
.teacher-img-content:hover .course-price {
  opacity: 1;
  left: 35px;
  visibility: visible;
}

.secound-teacher-section {
  background-color: #f7f7f7;
  padding: 105px 0px;
}
.secound-teacher-section .teacher-img-text {
  background-color: #fff;
  padding: 20px 30px 30px 30px;
  border-radius: 4px;
}
.secound-teacher-section .teacher-img-text .teacher-social-list {
  position: absolute;
  top: 40%;
  left: 0;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  right: 0;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.secound-teacher-section .teacher-img-text .teacher-social-list li {
  height: 30px;
  width: 30px;
  text-align: center;
  border: 2px solid #17d0cf;
  line-height: 30px;
  color: #fff;
  margin: 0 2px;
  border-radius: 100%;
}
.secound-teacher-section .teacher-img-text .teacher-name {
  display: block;
  font-size: 18px;
  font-weight: 700;
  color: #333333;
}
.secound-teacher-section .teacher-img-text .teacher-designation {
  font-size: 13px;
  color: #17d0cf;
}
.secound-teacher-section
  .teacher-img-text
  .teacher-img-social
  .blakish-overlay {
  border-radius: 100%;
  height: 190px;
  width: 190px;
  margin: 0 auto;
  left: -13px;
  top: 5px;
  right: 0;
}
.secound-teacher-section .teacher-img-text:hover .blakish-overlay {
  opacity: 1;
  visibility: visible;
}
.secound-teacher-section .teacher-img-text:hover .teacher-social-list {
  top: 50%;
  opacity: 1;
  visibility: visible;
}

.teacher-img-social {
  z-index: 1;
}
.teacher-img-social img {
  border: 5px solid #fff;
  border-radius: 100%;
}
.teacher-img-social:after {
  content: "";
  position: absolute;
  width: 210px;
  height: 210px;
  background: #17d0cf;
  background: -moz-linear-gradient(left, #01a6fd 0%, #17d0cf 51%, #01a6fd 100%);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, #4eb3bf),
    color-stop(51%, #17d0cf),
    color-stop(100%, #4eb3bf)
  );
  background: -webkit-linear-gradient(
    left,
    #01a6fd 0%,
    #17d0cf 51%,
    #0071b0 100%
  );
  background: -o-linear-gradient(left, #01a6fd 0%, #17d0cf 51%, #01a6fd 100%);
  background: -ms-linear-gradient(left, #01a6fd 0%, #17d0cf 51%, #01a6fd 100%);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#01a6fd),
    color-stop(51%, #17d0cf),
    to(#01a6fd)
  );
  background: -webkit-linear-gradient(
    left,
    #01a6fd 0%,
    #17d0cf 51%,
    #01a6fd 100%
  );
  background: linear-gradient(to right, #01a6fd 0%, #17d0cf 51%, #01a6fd 100%);
  background-size: 200% auto;
  top: -5px;
  left: -5px;
  border-radius: 100%;
  z-index: -1;
  right: 0;
}

.secound-teacher-section .owl-prev,
.secound-teacher-section .owl-nav .owl-next,
.owl-nav .secound-teacher-section .owl-next,
.secound-teacher-section .testimonial-slide-area .owl-next,
.testimonial-slide-area .secound-teacher-section .owl-next,
.secound-teacher-section .owl-next {
  background-color: #fff;
}

.secound-teacher-section .genius-btn {
  margin: 0 auto;
  margin-top: 65px;
}

.genius-teacher-section-2 {
  padding-bottom: 80px;
}
.genius-teacher-section-2 .teacher-img-content {
  background-color: inherit;
  padding: 0;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 25px;
  height: 210px;
}
.genius-teacher-section-2 .teacher-img-content .teacher-cntent {
  position: absolute;
  top: 15px;
  left: 30px;
  z-index: 1;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  opacity: 0;
}
.genius-teacher-section-2 .teacher-img-content .teacher-social-name ul {
  margin-bottom: 20px !important;
}
.genius-teacher-section-2 .teacher-img-content .teacher-category {
  bottom: 50px;
  top: inherit;
  right: 20px;
  display: none;
  z-index: 11;
}
.genius-teacher-section-2 .teacher-img-content:after {
  position: absolute;
  content: "";
  background: #17d0cf;
  background: -moz-linear-gradient(left, #01a6fd 0%, #17d0cf 51%, #01a6fd 100%);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, #4eb3bf),
    color-stop(51%, #17d0cf),
    color-stop(100%, #4eb3bf)
  );
  background: -webkit-linear-gradient(
    left,
    #01a6fd 0%,
    #17d0cf 51%,
    #0071b0 100%
  );
  background: -o-linear-gradient(left, #01a6fd 0%, #17d0cf 51%, #01a6fd 100%);
  background: -ms-linear-gradient(left, #01a6fd 0%, #17d0cf 51%, #01a6fd 100%);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#01a6fd),
    color-stop(51%, #17d0cf),
    to(#01a6fd)
  );
  background: -webkit-linear-gradient(
    left,
    #01a6fd 0%,
    #17d0cf 51%,
    #01a6fd 100%
  );
  background: linear-gradient(to right, #01a6fd 0%, #17d0cf 51%, #01a6fd 100%);
  background-size: 200% auto;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 0;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  opacity: 0;
}
.genius-teacher-section-2 .teacher-img-content:hover:after {
  opacity: 0.8;
}
.genius-teacher-section-2 .teacher-img-content:hover .teacher-category {
  display: block;
}
.genius-teacher-section-2 .teacher-img-content:hover .teacher-cntent {
  opacity: 1;
}
.genius-teacher-section-2 .teacher-social-name li {
  background-color: #fff;
}

.teacher-page-section {
  padding: 80px 0px;
}
.teacher-page-section .couse-pagination {
  margin-top: 60px;
}

.teacher-pic-content {
  margin-bottom: 30px;
}
.teacher-pic-content .teacher-hover-item {
  display: inline-block;
  position: absolute;
  top: 35px;
  left: 15px;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
.teacher-pic-content .teacher-hover-item .teacher-text {
  color: #fff;
}
.teacher-pic-content .teacher-social-name {
  width: 100%;
  max-width: inherit;
  margin-bottom: 5px;
}
.teacher-pic-content .teacher-social-name li {
  background-color: #fff;
  color: #17d0cf;
}
.teacher-pic-content .teacher-next {
  height: 45px;
  width: 45px;
  background-color: #fff;
  position: absolute;
  right: -40px;
  bottom: 0;
  line-height: 52px;
  border-top-left-radius: 10px;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
.teacher-pic-content .teacher-next a {
  display: block;
  width: 100%;
}
.teacher-pic-content .teacher-next i {
  font-size: 20px;
}
.teacher-pic-content .teacher-img-content {
  background-color: inherit;
  padding: 0;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 0;
}
.teacher-pic-content .teacher-img-content:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  z-index: 0;
}
.teacher-pic-content .teacher-name-designation {
  margin-top: 10px;
}
.teacher-pic-content .teacher-name-designation .teacher-name {
  display: block;
  font-size: 18px;
  font-weight: 700;
  color: #333333;
}
.teacher-pic-content .teacher-name-designation .teacher-designation {
  font-size: 13px;
  color: #17d0cf;
}
.teacher-pic-content:hover .teacher-img-content::after {
  opacity: 0.8;
}
.teacher-pic-content:hover .teacher-hover-item {
  opacity: 1;
  visibility: visible;
  left: 25px;
}
.teacher-pic-content:hover .teacher-next {
  opacity: 1;
  right: 0;
  visibility: visible;
}

.first-widget {
  margin-top: 0 !important;
}

.teacher-details-area {
  padding: 80px 0px;
}

.course-img {
  margin-bottom: 15px;
}

.course-desc {
  color: #333333;
}

.teacher-details-text .section-title-2 {
  margin-bottom: 50px;
}
.teacher-details-text .section-title-2 h2 {
  font-size: 30px;
}
.teacher-details-text .section-title-2 h2:after {
  bottom: -45px;
}

.teacher-details-text .teacher-deg {
  font-weight: 700;
  font-size: 15px;
  margin-top: 5px;
}
.teacher-details-text .teacher-deg span {
  color: #17d0cf;
}

.teacher-desc-social {
  margin-bottom: 28px;
}
.teacher-desc-social li {
  height: 80px;
  width: 80px;
  text-align: center;
  background-color: #f7f7f7;
  color: #444444;
  border-radius: 4px;
  font-size: 13px;
  margin-right: 5px;
  padding-top: 15px;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
.teacher-desc-social li i {
  color: #bababa;
  font-size: 30px;
  margin-bottom: 5px;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
.teacher-desc-social li:hover {
  background-color: #17d0cf;
  color: #fff;
}
.teacher-desc-social li:hover i {
  color: #fff;
}

.teacher-address .address-details li {
  color: #000;
  font-size: 18px;
  margin-bottom: 10px;
  display: inline-block;
}
.teacher-address .address-details li b {
  font-weight: 900;
}
.teacher-address .address-details li .addrs-icon {
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 20px;
  border-radius: 5px;
  color: #fff;
  margin-right: 15px;
  float: left;
  background-color: #01a6fd;
}
.teacher-address .address-details li .add-info {
  overflow: hidden;
  margin-top: 10px;
}

.about-teacher .section-title-2 {
  margin-bottom: 35px;
}
.about-teacher .section-title-2 h2:after {
  display: none;
}

.about-teacher p {
  font-weight: 300;
  font-size: 20px;
  color: #333333;
}

.category-slide .category-icon-title {
  padding: 35px 10px;
  border-radius: 0;
  position: relative;
  margin-bottom: 0;
}
.category-slide .category-icon-title:after {
  border-radius: 0;
}

.category-slide .owl-nav {
  top: 35%;
  width: 110%;
  -webkit-transform: translateY(-40%);
  -ms-transform: translateY(-40%);
  transform: translateY(-40%);
  left: -40px;
  z-index: -1;
}

.category-slide .owl-nav .owl-prev,
.category-slide .owl-nav .owl-next,
.category-slide .owl-nav .owl-next {
  background-color: transparent;
}

.category-slide .owl-nav .owl-prev,
.category-slide .owl-nav .owl-next {
  float: left;
}

.category-slide .owl-nav .owl-next {
  float: right;
}

.category-slide .owl-item.active:before {
  content: "";
  position: absolute;
  height: 195px;
  width: 1px;
  background-color: #eee;
  top: 0;
  right: 0;
  z-index: 1;
}

.category-slide .owl-item.active:last-child:before {
  display: none;
}

.category-slide .owl-stage-outer {
  padding: 5px;
}

.about-faq.faq-secound-home-version {
  background-image: inherit;
  padding-top: 0;
}
.about-faq.faq-secound-home-version .panel-group .panel {
  max-width: inherit;
}
.about-faq.faq-secound-home-version .panel-group .btn-link.collapsed {
  color: #333333;
  font-weight: 400;
  padding: 10px 0px;
}
.about-faq.faq-secound-home-version .panel-group .panel-title h3:before {
  background-color: #cfcdcd;
  top: 15px;
}
.about-faq.faq-secound-home-version .panel-group .panel-body {
  color: inherit;
}
.about-faq.faq-secound-home-version
  .panel-group
  .collapse.show
  .panel-body:before {
  height: 185%;
  top: -49px;
}
.about-faq.faq-secound-home-version .panel-group .btn-link.collapsed:after {
  color: #cfcdcd;
}
.about-faq.faq-secound-home-version ul.product-tab li {
  background-color: #f7f7f7;
  font-size: 15px;
  font-weight: 400;
  color: #c4c4c4;
}
.about-faq.faq-secound-home-version .ques-ans h3 {
  color: #0f0f0f;
}
.about-faq.faq-secound-home-version .tab-container {
  margin-left: 15px;
}

.teacher-contact {
  padding-top: 25px;
}

.teacher-faq-form label {
  display: block;
  text-transform: uppercase;
  color: #333333;
  font-weight: 700;
  font-size: 14px;
}

.teacher-faq-form input,
.teacher-faq-form .register-form-area select,
.register-form-area .teacher-faq-form select,
.teacher-faq-form .register-form-area textarea,
.register-form-area .teacher-faq-form textarea,
.teacher-faq-form textarea {
  width: 100%;
  height: 60px;
  border-radius: 4px;
  background-color: #f7f7f7;
  border: none;
  margin-bottom: 30px;
  padding: 15px;
}

.teacher-faq-form textarea {
  height: 160px;
}

.teacher-faq-form .nws-button {
  height: 60px;
  width: 195px;
  line-height: 60px;
  border-radius: 5px;
}
.teacher-faq-form .nws-button button {
  border: none;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
  background-color: transparent;
}

.one-page-teacher {
  padding: 100px 0px;
  background-image: url(../img/banner/tesit-b-1.jpg);
}
.one-page-teacher .section-title span,
.one-page-teacher .section-title h2 {
  color: #fff;
}

/*---------------------------------------------------- */
/*Best product  section*/
/*----------------------------------------------------*/
.best-product-section {
  padding: 125px 0px 50px 0px;
}
.best-product-section .course-title h3 {
  font-size: 18px;
}
.best-product-section .course-title:after {
  display: none;
}
.best-product-section .price-start {
  font-size: 12px;
  font-weight: 700;
  margin-top: 5px;
}
.best-product-section .price-start span {
  color: #17d0cf;
}

.best-product-section.home_2 {
  padding: 50px 0px 100px;
}

.add-cart {
  color: #bbbbbb;
  height: 40px;
  width: 40px;
  line-height: 40px;
  float: right;
  border-radius: 100%;
  background-color: #fff;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  margin-top: 10px;
  overflow: hidden;
  position: relative;
}
.add-cart:after {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  background: #17d0cf;
  background: -moz-linear-gradient(left, #01a6fd 0%, #17d0cf 51%, #01a6fd 100%);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, #4eb3bf),
    color-stop(51%, #17d0cf),
    color-stop(100%, #4eb3bf)
  );
  background: -webkit-linear-gradient(
    left,
    #01a6fd 0%,
    #17d0cf 51%,
    #0071b0 100%
  );
  background: -o-linear-gradient(left, #01a6fd 0%, #17d0cf 51%, #01a6fd 100%);
  background: -ms-linear-gradient(left, #01a6fd 0%, #17d0cf 51%, #01a6fd 100%);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#01a6fd),
    color-stop(51%, #17d0cf),
    to(#01a6fd)
  );
  background: -webkit-linear-gradient(
    left,
    #01a6fd 0%,
    #17d0cf 51%,
    #01a6fd 100%
  );
  background: linear-gradient(to right, #01a6fd 0%, #17d0cf 51%, #01a6fd 100%);
  background-size: 200% auto;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  left: 0;
  z-index: 0;
  opacity: 0;
  border-radius: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}
.add-cart i {
  position: relative;
  z-index: 1;
}

.product-img-text {
  background-color: #f1f1f3;
  padding: 25px 20px;
  display: inline-block;
  width: 97%;
  margin: 5px;
  border-radius: 4px;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
.product-img-text:hover {
  background-color: #fff;
  -webkit-box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.1);
}
.product-img-text:hover .add-cart {
  color: #fff;
}
.product-img-text:hover .add-cart::after {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.faq-secound-home-version .best-product-section {
  padding-top: 30px;
}
.faq-secound-home-version .best-product-section .section-title-2 h2 {
  color: #fff;
}
.faq-secound-home-version .best-product-section .owl-prev,
.faq-secound-home-version .best-product-section .owl-nav .owl-next,
.owl-nav .faq-secound-home-version .best-product-section .owl-next,
.faq-secound-home-version
  .best-product-section
  .testimonial-slide-area
  .owl-next,
.testimonial-slide-area
  .faq-secound-home-version
  .best-product-section
  .owl-next,
.faq-secound-home-version .best-product-section .owl-next {
  background-color: #4175a4;
}

.home-third-best-product {
  padding: 20px 0px 80px;
}

.shop-product-section {
  padding: 80px 0px 20px;
}

.genius-shop-item .best-product-section {
  padding-top: 0;
}
.genius-shop-item .best-product-section .product-img-text {
  width: 100%;
  margin: 0;
  margin-bottom: 30px;
}

.genius-shop-item .product-list-item {
  background-color: #f7f7f7;
  padding: 25px 20px;
  border-radius: 4px;
}

.genius-shop-item .course-page-section {
  padding-top: 0;
}

.ui-slider-horizontal {
  height: 3px;
  border: none;
  background-color: #dddddd;
}

.price-range .ui-widget.ui-widget-content {
  border: none;
}

.price-range input,
.price-range .register-form-area select,
.register-form-area .price-range select,
.price-range .register-form-area textarea,
.register-form-area .price-range textarea {
  position: absolute;
  font-size: 12px;
  right: -70px;
  top: -13px;
  color: #b2afaf;
  border: none;
  font-weight: 700;
}

#slider-range {
  width: 73%;
  float: right;
  margin-top: 6px;
}
#slider-range .ui-widget-header {
  background-color: #333333;
}

.ui-slider-horizontal .ui-slider-handle {
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background-color: #17d0cf;
  border: none;
  cursor: pointer;
  outline: none;
  top: -3px;
}

.inner-title {
  font-size: 12px;
  font-weight: 500;
  float: left;
  padding-left: 25px;
  position: relative;
}
.inner-title:after {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  border-radius: 4px;
  border: 1px solid #d9d4d4;
  left: 0;
  top: 1px;
}

.product-highlights .inner-title {
  float: none;
  font-size: 14px;
  color: #333333;
  font-weight: 500;
  margin-bottom: 10px;
}
.product-highlights .inner-title:last-child {
  margin-bottom: 0;
}

.best-sell-title-price {
  margin-top: 30px;
}
.best-sell-title-price h3 {
  font-size: 14px;
  font-weight: 700;
  color: #333333;
}

.best-sellers-pic-text {
  background-color: #f7f7f7;
  display: inline-block;
  width: 100%;
  padding: 15px 15px 25px 15px;
  margin-bottom: 4px;
}
.best-sellers-pic-text .best-sell-pic {
  height: 105px;
  width: 105px;
  float: left;
  margin-right: 20px;
}
.best-sellers-pic-text .b-price {
  font-size: 12px;
  color: #17d0cf;
  font-weight: 700;
}

.best-sellers-item .couse-pagination {
  margin-top: 15px;
}
.best-sellers-item .couse-pagination li {
  font-size: 12px;
}

.best-product-4 {
  background-color: #f7f7f7;
  padding-bottom: 100px;
}
.best-product-4 .product-img-text {
  background-color: #fff;
}
.best-product-4 .owl-nav .owl-prev,
.best-product-4 .owl-nav .owl-next,
.best-product-4 .owl-nav .owl-next {
  background-color: #fff;
}

/*---------------------------------------------------- */
/*FAQ  section*/
/*----------------------------------------------------*/
.faq-section {
  padding: 50px 0px 125px 0px;
}

ul.product-tab li {
  cursor: pointer;
  margin: 0px 40px 0px 0px;
  font-size: 18px;
  position: relative;
  color: #777777;
  font-weight: 500;
  text-transform: uppercase;
}

ul.product-tab li:after {
  position: absolute;
  content: "";
  height: 2px;
  width: 100%;
  top: 30px;
  left: 0;
  opacity: 0;
  background-color: #17d0cf;
}

ul.product-tab li.active {
  color: #333333;
}

ul.product-tab li.active:after {
  opacity: 1;
}

.tab-content-1 {
  display: none;
}
.tab-content-1 p {
  color: #777777;
}

.ques-ans h3 {
  font-size: 22px;
  font-weight: 500;
  color: #333333;
  margin-bottom: 20px;
}

.faq-secound-home-version {
  background-image: url(../img/banner/fq-1.jpg);
  padding: 110px 0px 100px 0px;
}
.faq-secound-home-version .section-title h2,
.faq-secound-home-version .section-title span {
  color: #fff;
}
.faq-secound-home-version ul.product-tab li {
  background-color: #006dad;
  color: #fff;
  padding: 5px 15px;
  font-size: 16px;
  border-radius: 4px;
  z-index: 1;
  margin: 0px 5px 0px 0px;
}
.faq-secound-home-version ul.product-tab li:after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: #17d0cf;
  background: -moz-linear-gradient(left, #01a6fd 0%, #17d0cf 51%, #01a6fd 100%);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, #4eb3bf),
    color-stop(51%, #17d0cf),
    color-stop(100%, #4eb3bf)
  );
  background: -webkit-linear-gradient(
    left,
    #01a6fd 0%,
    #17d0cf 51%,
    #0071b0 100%
  );
  background: -o-linear-gradient(left, #01a6fd 0%, #17d0cf 51%, #01a6fd 100%);
  background: -ms-linear-gradient(left, #01a6fd 0%, #17d0cf 51%, #01a6fd 100%);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#01a6fd),
    color-stop(51%, #17d0cf),
    to(#01a6fd)
  );
  background: -webkit-linear-gradient(
    left,
    #01a6fd 0%,
    #17d0cf 51%,
    #01a6fd 100%
  );
  background: linear-gradient(to right, #01a6fd 0%, #17d0cf 51%, #01a6fd 100%);
  background-size: 200% auto;
  left: 0;
  top: 0;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  border-radius: 4px;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
}
.faq-secound-home-version ul.product-tab li.active {
  color: #fff;
}
.faq-secound-home-version ul.product-tab li.active:after {
  opacity: 1;
  visibility: visible;
}
.faq-secound-home-version .ques-ans h3 {
  color: #fff;
}
.faq-secound-home-version .tab-content-1 {
  display: none;
}
.faq-secound-home-version .tab-content-1 p {
  color: #777777;
}

.faq_3 {
  padding-bottom: 40px;
}

.panel-group .panel {
  border-radius: 0;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-bottom: 25px;
  max-width: 625px;
  margin: 0 auto;
}

.panel-group .panel-body {
  color: #67a0c9;
  position: relative;
}
.panel-group .panel-body:before {
  position: absolute;
  left: -15px;
  top: -42px;
  content: "";
  background-color: #17d0cf;
  width: 3px;
  height: 0%;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}

.panel-group .collapse.show .panel-body:before {
  height: 150%;
}

.panel-group .panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 26px;
  color: inherit;
  position: relative;
}
.panel-group .panel-title h3 {
  position: relative;
}
.panel-group .panel-title h3:before {
  position: absolute;
  width: 3px;
  height: 25px;
  left: -15px;
  content: "";
  background-color: #4a8ec0;
  top: 22px;
}
.panel-group .panel-title .btn-link {
  display: block;
  padding: 15px 0px 15px;
  position: relative;
  width: 100%;
  text-align: left;
  text-decoration: none;
  font-size: 22px;
  font-weight: 500;
  color: #17d0cf;
}
.panel-group .panel-title .btn-link:before {
  content: "\f139";
  position: absolute;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  right: 20px;
  top: 20px;
  font-size: 16px;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  color: #17d0cf;
}
.panel-group .panel-title .btn-link:after {
  content: "";
  font-size: 16px;
  position: absolute;
  right: 20px;
  top: 20px;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  color: #17d0cf;
}
.panel-group .panel-title .btn-link:hover {
  text-decoration: none;
  color: #17d0cf;
}

.panel-group .btn-link.collapsed {
  color: #fff;
}
.panel-group .btn-link.collapsed:before {
  content: " ";
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}
.panel-group .btn-link.collapsed:after {
  content: "\f13a";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  color: #4d8fbe;
}

#accordion .btn {
  white-space: inherit;
}

.faq-page-section {
  padding: 80px 0px 80px 0px;
}
.faq-page-section .panel-group .btn-link.collapsed {
  color: #333333;
  font-size: 20px;
  font-weight: 400;
}
.faq-page-section .panel-group .panel-title .btn-link:before,
.faq-page-section .panel-group .panel-title .btn-link:after {
  right: 0;
}
.faq-page-section .panel-group .panel-title .btn-link:after {
  color: #ccc;
}
.faq-page-section .panel-group .panel-title .btn-link {
  font-size: 20px;
}
.faq-page-section .panel-group .panel-body:before {
  top: -50px;
}
.faq-page-section .panel-group .panel-title h3:before {
  background-color: #ccc;
}
.faq-page-section .panel-group .panel-body {
  color: inherit;
}
.faq-page-section .faq-secound-home-version {
  background-image: inherit;
  padding: 0;
}
.faq-page-section .faq-secound-home-version ul.product-tab li {
  background-color: #f7f7f7;
  color: #ccc;
}
.faq-page-section .faq-secound-home-version ul.product-tab li.active {
  color: #fff;
}
.faq-page-section .panel-group .panel {
  max-width: 100%;
}

.faq-page-section .faq-secound-home-version .ques-ans h3 {
  color: #333333;
}

/*---------------------------------------------------- */
/*Course category  section*/
/*----------------------------------------------------*/
.course-category-section {
  background-color: #f7f7f7;
  padding: 125px 0px 125px;
}

.category-icon {
  position: relative;
  z-index: 1;
}
.category-icon i {
  font-size: 60px;
}

.category-title {
  margin-top: 10px;
  position: relative;
  z-index: 1;
}
.category-title h4 {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
}

.category-icon-title {
  background-color: #fff;
  padding: 35px;
  border-radius: 4px;
  margin-bottom: 30px;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  position: relative;
}
.category-icon-title:after {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  background: #17d0cf;
  background: -moz-linear-gradient(left, #01a6fd 0%, #17d0cf 51%, #01a6fd 100%);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, #4eb3bf),
    color-stop(51%, #17d0cf),
    color-stop(100%, #4eb3bf)
  );
  background: -webkit-linear-gradient(
    left,
    #01a6fd 0%,
    #17d0cf 51%,
    #0071b0 100%
  );
  background: -o-linear-gradient(left, #01a6fd 0%, #17d0cf 51%, #01a6fd 100%);
  background: -ms-linear-gradient(left, #01a6fd 0%, #17d0cf 51%, #01a6fd 100%);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#01a6fd),
    color-stop(51%, #17d0cf),
    to(#01a6fd)
  );
  background: -webkit-linear-gradient(
    left,
    #01a6fd 0%,
    #17d0cf 51%,
    #01a6fd 100%
  );
  background: linear-gradient(to right, #01a6fd 0%, #17d0cf 51%, #01a6fd 100%);
  background-size: 200% auto;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  left: 0;
  top: 0;
  z-index: 0;
  opacity: 0;
  border-radius: 4px;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}
.category-icon-title:hover::after {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.category-icon-title:hover .category-title h4 {
  color: #fff;
}
.category-icon-title:hover .category-icon i {
  color: #fff;
  -webkit-text-fill-color: inherit;
}
.category-icon-title:hover {
  -webkit-box-shadow: 0px 10px 15px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 15px 2px rgba(0, 0, 0, 0.1);
}

.home-secound-version {
  background-color: #fff;
}
.home-secound-version .category-icon-title {
  background-color: #f7f7f7;
}

/*---------------------------------------------------- */
/*why choose  section*/
/*----------------------------------------------------*/
.why-choose-section {
  background-image: url(../img/banner/wc-1.jpg);
  padding-top: 115px;
}
.why-choose-section .section-title h2,
.why-choose-section .section-title span {
  color: #fff;
}

.features-icon {
  height: 80px;
  width: 80px;
  border-radius: 100%;
  line-height: 80px;
  font-size: 40px;
  float: left;
  margin-right: 25px;
}
.features-icon i {
  color: #fff;
}

.features-text-title h3 {
  font-size: 22px;
  font-weight: 700;
  color: #fff;
}

.features-text {
  overflow: hidden;
  padding-top: 10px;
}
.features-text .features-text-dec {
  color: #84bfeb;
}

.extra-icon-text {
  margin-bottom: 45px;
}

.extra-right .features-icon {
  float: right;
  margin-left: 25px;
  margin-right: 0;
}

.version-four .features-icon {
  position: relative;
}
.version-four .features-icon .feat-tag span {
  position: absolute;
  background-color: #fff;
  height: 25px;
  width: 25px;
  border-radius: 100%;
  text-align: center;
  line-height: 25px;
  font-weight: 700;
  color: #17d0cf;
  right: -10px;
  top: 25px;
  font-size: 13px;
}

.version-four .extra-right .features-icon .feat-tag span {
  left: -10px;
  right: inherit;
}

.course-advantage {
  padding-top: 65px;
}

.service-slide_3 {
  margin-top: 35px;
}
.service-slide_3 .service-icon {
  float: none !important;
  margin-bottom: 15px;
}
.service-slide_3 .service-text-icon {
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 4px;
}
.service-slide_3 .service-text p {
  font-size: 15px;
  color: #88c3ef;
}
.service-slide_3 .service-text h3 {
  font-size: 18px;
  color: #333333;
  margin-bottom: 10px;
}
.service-slide_3 .owl-nav {
  top: -70px;
}

/*---------------------------------------------------- */
/*Contact  section*/
/*----------------------------------------------------*/
.contact-area-section,
.contact_secound_section {
  background-image: url(../img/banner/contact-b.jpg);
  padding: 105px 0px 105px;
}
.contact-area-section .section-title h2,
.contact_secound_section .section-title h2 {
  color: #fff;
  margin-bottom: 25px;
}
.contact-area-section .section-title span,
.contact_secound_section .section-title span {
  color: #fff;
}
.contact-area-section .section-title p,
.contact_secound_section .section-title p {
  color: #fff;
}

.contact-left-content {
  max-width: 500px;
}

.address-icon {
  height: 60px;
  width: 60px;
  border: 5px solid;
  line-height: 60px;
  margin-right: 20px;
  border: 4px solid transparent;
  -webkit-border-image: -webkit-linear-gradient(21deg, #10abff, #1beabd);
  -o-border-image: -o-linear-gradient(21deg, #10abff, #1beabd);
  border-image: linear-gradient(69deg, #10abff, #1beabd);
  border-image-slice: 1;
  border-radius: 40px;
  background-origin: border-box;
  background-clip: content-box, border-box;
}
.address-icon i {
  font-size: 30px;
  color: #fff;
}

.address-details li {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
}
.address-details li span {
  font-weight: 300;
}

.contact-address-details {
  width: 100%;
  margin-bottom: 15px;
  padding-bottom: 15px;
  display: inline-block;
  border-bottom: 1px solid #207fba;
}
.contact-address-details:last-child {
  border-bottom: none;
}

.google-map-container {
  height: 600px;
  background-color: #dddddd;
}

#google-map {
  top: 0;
  width: 940px;
  height: 100%;
  -webkit-box-shadow: -15px -10px 40px 5px rgba(0, 0, 0, 0.1);
  box-shadow: -15px -10px 40px 5px rgba(0, 0, 0, 0.1);
  position: absolute;
}

.contact_secound_section {
  background-image: url(../img/banner/cf-1.png);
  padding: 270px 0px 0px;
  background-color: #f7f7f7;
}

.contact_secound_form {
  margin-left: 80px;
}
.contact_secound_form .section-title-2 h2 {
  color: #fff;
  font-size: 25px;
  font-weight: 700;
  display: table;
}
.contact_secound_form .section-title-2 h2:after {
  right: -65px;
  left: inherit;
  bottom: 5px;
}
.register-form-area .contact-form .contact-info {
  margin: 12px;
}
.contact_secound_form .contact_form input,
.contact_secound_form .contact_form .register-form-area select,
.register-form-area .contact_secound_form .contact_form select,
.contact_secound_form .contact_form .register-form-area textarea,
.register-form-area .contact_secound_form .contact_form textarea {
  width: 45%;
  float: left;
  background-color: transparent;
  border: none;
  padding: 25px 0px;
  border-bottom: 1px solid #73a3c4;
  margin-right: 20px;
  height: 35px;
}
.contact_secound_form .contact_form input::-webkit-input-placeholder,
.contact_secound_form
  .contact_form
  .register-form-area
  select::-webkit-input-placeholder,
.register-form-area
  .contact_secound_form
  .contact_form
  select::-webkit-input-placeholder,
.contact_secound_form
  .contact_form
  .register-form-area
  textarea::-webkit-input-placeholder,
.register-form-area
  .contact_secound_form
  .contact_form
  textarea::-webkit-input-placeholder {
  color: #669fc9;
}
.contact_secound_form .contact_form input:-ms-input-placeholder,
.contact_secound_form
  .contact_form
  .register-form-area
  select:-ms-input-placeholder,
.register-form-area
  .contact_secound_form
  .contact_form
  select:-ms-input-placeholder,
.contact_secound_form
  .contact_form
  .register-form-area
  textarea:-ms-input-placeholder,
.register-form-area
  .contact_secound_form
  .contact_form
  textarea:-ms-input-placeholder {
  color: #669fc9;
}
.contact_secound_form .contact_form input::-ms-input-placeholder,
.contact_secound_form
  .contact_form
  .register-form-area
  select::-ms-input-placeholder,
.register-form-area
  .contact_secound_form
  .contact_form
  select::-ms-input-placeholder,
.contact_secound_form
  .contact_form
  .register-form-area
  textarea::-ms-input-placeholder,
.register-form-area
  .contact_secound_form
  .contact_form
  textarea::-ms-input-placeholder {
  color: #669fc9;
}
.contact_secound_form .contact_form input::placeholder,
.contact_secound_form .contact_form .register-form-area select::placeholder,
.register-form-area .contact_secound_form .contact_form select::placeholder,
.contact_secound_form .contact_form .register-form-area textarea::placeholder,
.register-form-area .contact_secound_form .contact_form textarea::placeholder {
  color: #669fc9;
}
.contact_secound_form .contact_form textarea {
  height: 155px;
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 20px 0px;
  border-bottom: 1px solid #73a3c4;
}
.contact_secound_form .contact_form textarea::-webkit-input-placeholder {
  color: #669fc9;
}
.contact_secound_form .contact_form textarea:-ms-input-placeholder {
  color: #669fc9;
}
.contact_secound_form .contact_form textarea::-ms-input-placeholder {
  color: #669fc9;
}
.contact_secound_form .contact_form textarea::placeholder {
  color: #669fc9;
}
.contact_secound_form .nws-button {
  height: 60px;
  width: 280px;
  line-height: 60px;
  border-radius: 4px;
  margin: 0 auto;
  margin-top: 30px;
}
.contact_secound_form .nws-button button {
  background-color: transparent;
  border: none;
  font-weight: 700;
  color: #fff;
}

.contact-form-area_3 {
  background-color: #f7f7f7;
  padding: 110px 0px;
}

.contact_third_form .contact-info input,
.contact_third_form .contact-info .register-form-area select,
.register-form-area .contact_third_form .contact-info select,
.contact_third_form .contact-info .register-form-area textarea,
.register-form-area .contact_third_form .contact-info textarea {
  width: 100%;
  height: 50px;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #dddddd;
}

.contact_third_form textarea {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #dddddd;
  width: 100%;
  height: 150px;
  margin-top: 40px;
}

.contact_third_form .nws-button {
  height: 60px;
  width: 200px;
  line-height: 60px;
  border-radius: 4px;
  margin: 0 auto;
  margin-top: 70px;
}
.contact_third_form .nws-button button {
  background-color: transparent;
  border: none;
  font-weight: 700;
  color: #fff;
}

.contact-page-section {
  padding: 80px 0px;
}
.contact-page-section .social-contact .category-icon-title {
  width: 165px;
  float: left;
  position: relative;
}
.contact-page-section .social-contact .category-icon-title:before {
  content: "";
  position: absolute;
  height: 165px;
  width: 1px;
  background-color: #eee;
  top: 0;
  right: 0;
  z-index: 1;
}
.contact-page-section
  .social-contact
  .category-icon-title:before:last-child:before {
  opacity: 0;
}
.contact-page-section .social-contact .category-icon-title .category-title h4 {
  font-size: 16px;
}
.contact-page-section .category-icon-title:last-child::before {
  display: none;
}

.contact-page-version .contact_third_form {
  background-color: #fff;
  padding: 30px 30px 65px 30px;
  border-radius: 4px;
}

/*---------------------------------------------------- */
/*Blog  section*/
/*----------------------------------------------------*/
.blog-item-post {
  padding: 105px 0px 0px 0px;
}

.blog-content-details {
  padding-bottom: 80px;
  border-bottom: 1px solid #eeeeee;
}

.short-filter-tab {
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
}

.shorting-filter select {
  padding: 8px 15px;
  border-radius: 4px;
  background-color: #f7f7f7;
  border: none;
  color: #333333;
  font-weight: 700;
  margin-left: 15px;
}

.blog-button ul.product-tab li {
  margin: 0px 5px 0px 0px;
  font-size: 22px;
  padding: 5px 10px;
  background-color: #f7f7f7;
  border-radius: 4px;
}

.blog-button ul.product-tab li:after {
  display: none;
}

.blog-button ul.product-tab li.active {
  color: #fff;
  background-color: #17d0cf;
}

.blog-post-img-content {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 35px;
  margin-bottom: 30px;
}
.blog-post-img-content .course-price {
  bottom: 20px;
  top: inherit;
}
.blog-post-img-content .blog-img-date {
  border-radius: 4px;
  margin-bottom: 30px;
}

.blog-title-content h3 {
  font-size: 25px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 15px;
}

.blog-title-content .blog-content {
  font-size: 17px;
  margin-bottom: 22px;
}

.genius-post-item {
  padding-bottom: 45px;
}

.list-blog-item {
  background-color: #f7f7f7;
  border-radius: 4px;
  margin-bottom: 30px;
}
.list-blog-item .blog-post-img-content {
  padding: 0;
  margin: 0;
}
.list-blog-item .blog-img-date {
  margin: 0;
}
.list-blog-item .blog-title-content {
  padding-top: 30px;
}

.side-bar-search {
  position: relative;
}
.side-bar-search input,
.side-bar-search .register-form-area select,
.register-form-area .side-bar-search select,
.side-bar-search .register-form-area textarea,
.register-form-area .side-bar-search textarea {
  height: 40px;
  width: 100%;
  background-color: #f7f7f7;
  padding: 0px 15px;
  border: none;
  border-radius: 4px;
}
.side-bar-search button {
  position: absolute;
  top: 8px;
  right: 10px;
  background-color: #ccc;
  border: none;
  border-radius: 100%;
  padding: 3px 6px;
  color: #fff;
  font-size: 14px;
}

.side-bar-widget {
  margin: 30px 0px;
  display: inline-block;
  width: 100%;
}
.side-bar-widget .widget-title {
  font-size: 30px;
  font-weight: 300;
  color: #333333;
  margin-bottom: 20px;
}
.side-bar-widget .widget-title span {
  font-weight: 700;
}
.side-bar-widget .widget-title:after {
  display: none;
}
.side-bar-widget .post-categori {
  background-color: #f7f7f7;
  border-radius: 4px;
}
.side-bar-widget .post-categori .cat-item {
  position: relative;
  padding: 15px 20px 15px 35px;
  border-bottom: 1px solid #eeeeee;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
.side-bar-widget .post-categori .cat-item:before {
  font-family: "Font Awesome 5 Free";
  position: absolute;
  top: 19px;
  left: 19px;
  font-size: 10px;
  font-weight: 700;
  content: "\f054";
}
.side-bar-widget .post-categori .cat-item:hover {
  color: #333333;
}
.side-bar-widget .latest-news-thumbnile {
  height: 80px;
  width: 80px;
}
.side-bar-widget .latest-news-area {
  margin-bottom: 20px;
  padding-bottom: 15px;
}
.side-bar-widget .latest-title {
  font-size: 17px;
}
.side-bar-widget .tag-clouds li {
  border: 1px solid #cfcaca;
  color: #cbc8c8;
  padding: 5px 15px;
  margin-bottom: 8px;
  margin-right: 2px;
  border-radius: 4px;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
.side-bar-widget .tag-clouds li:hover {
  background-color: #17d0cf;
  border-color: #17d0cf;
  color: #fff;
}
.side-bar-widget .best-course-text {
  position: absolute;
  top: 50%;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
}
.side-bar-widget .best-course-text h3 {
  color: #fff;
}
.side-bar-widget .best-course-text .course-meta span {
  float: none;
  margin-right: 0;
  color: #fff;
  margin: 0px 10px;
}
.side-bar-widget .best-course-pic-text:hover .best-course-text {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.blog-details-section {
  padding: 80px 0px;
  border-bottom: 1px solid #ccc;
}

.blog-details-content h2 {
  font-size: 35px;
  color: #333333;
  font-weight: 500;
}

.blog-details-content .date-meta {
  margin-top: 15px;
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
  margin-bottom: 30px;
}
.blog-details-content .date-meta span {
  font-size: 13px;
  color: #ccc;
  font-weight: 700;
  margin-right: 20px;
}
.blog-details-content .date-meta span i {
  color: #17d0cf;
}

.blog-details-content h3 {
  font-size: 20px;
  font-weight: 700;
  color: #333333;
  margin-bottom: 30px;
}

.blog-details-content p {
  font-size: 20px;
  color: #333333;
  font-weight: 300;
  margin-bottom: 20px;
}

.blog-details-content .share-text {
  font-weight: 700;
  color: #333333;
  margin-top: 8px;
}

.blog-details-content .blog-share-tag {
  display: inline-block;
  width: 100%;
  margin: 40px 0px 15px 0px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
}

.blog-details-content .share-social ul li {
  font-size: 25px;
  margin-left: 30px;
}

.blog-details-content .blog-category li {
  padding: 5px 10px;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  border-radius: 4px;
}
.blog-details-content .blog-category li:hover {
  background-color: #17d0cf;
  color: #fff;
}

.blog-details-content .author-comment {
  padding: 25px;
  margin-top: 20px;
  border-radius: 4px;
  background-color: #f5f5f5;
}
.blog-details-content .author-comment .author-img {
  height: 70px;
  width: 70px;
  float: left;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 20px;
}
.blog-details-content .author-comment .author-designation-comment {
  font-size: 12px;
  font-weight: 700;
}
.blog-details-content .author-comment .author-designation-comment span {
  color: #17d0cf;
}
.blog-details-content .author-comment .author-designation-comment p {
  font-size: 16px;
  color: #999999;
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 0;
}

.blog-details-content .next-prev-post {
  font-weight: 700;
  color: #333333;
  display: inline-block;
  width: 100%;
  margin-top: 25px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
  margin-bottom: 35px;
}
.blog-details-content .next-prev-post i {
  margin: 0 5px;
  color: #17d0cf;
}

.recent-post-item {
  padding-top: 32px;
}
.recent-post-item .blog-title-content h3 {
  margin-bottom: 0;
}

.blog-comment-area {
  margin-top: 10px;
}

.blog-comment-area .comment-list li {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 25px;
}

.blog-comment-area .section-title-2 {
  margin-bottom: 65px;
}
.blog-comment-area .section-title-2 h2 {
  font-size: 30px;
}

.blog-comment-area .comment-avater {
  height: 70px;
  width: 70px;
  float: left;
  margin-right: 20px;
}

.blog-comment-area .author-designation-comment {
  overflow: hidden;
  width: 100%;
  display: inline-block;
  margin-top: 10px;
}
.blog-comment-area .author-designation-comment h3 {
  font-size: 18px;
  font-weight: 700;
  color: #333333;
}

.blog-comment-area .author-name-rate {
  width: 88%;
  font-size: 13px;
  font-weight: 700;
  display: inline-block;
  margin-top: 20px;
}
.blog-comment-area .author-name-rate span {
  color: #17d0cf;
}

.blog-comment-area .time-comment {
  margin-right: 10px;
}

.blog-comment-area .comment-ratting li {
  font-size: 15px;
  color: #ffc926;
  border-bottom: none;
  margin-bottom: 0;
}

.reply-comment-box {
  margin-top: 20px;
}
.reply-comment-box .section-title-2 {
  margin-bottom: 30px;
}

/*---------------------------------------------------- */
/*course  section*/
/*----------------------------------------------------*/
.course-page-section {
  padding: 80px 0px 0px;
}
.course-page-section .short-filter-tab {
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
}
.course-page-section .course-list-view {
  overflow-x: auto;
  padding: 5px;
}
.course-page-section .shorting-filter select {
  margin-right: 10px;
  padding: 10px 15px;
}
.course-page-section table {
  width: 100%;
}
.course-page-section td,
.course-page-section th {
  text-align: left;
  padding: 20px 15px;
}
.course-page-section th {
  font-size: 12px;
  font-weight: 700;
  color: #333333;
  border-bottom: 3px solid #01a6fd;
}
.course-page-section tr {
  border-bottom: 1px solid #eee;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  padding: 15px;
}
.course-page-section tr:hover {
  -webkit-box-shadow: 0px 2px 10px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 10px 3px rgba(0, 0, 0, 0.1);
}
.course-page-section .list-head {
  padding: 0;
}
.course-page-section .list-head:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.course-page-section .course-list-img {
  height: 70px;
  width: 70px;
  float: left;
  margin-right: 20px;
}
.course-page-section .course-list-text {
  max-width: 225px;
  display: inline-block;
}
.course-page-section .course-list-text h3 {
  font-size: 16px;
  font-weight: 700;
  color: #333333;
}
.course-page-section .course-type-list span {
  background-color: #17d0cf;
  color: #fff;
  padding: 12px 20px;
  border-radius: 4px;
}
.course-page-section .course-meta .course-rate li {
  color: #bbbbbb;
}

.course-details-section {
  padding: 80px 0px 0px;
}

.course-single-text .course-title {
  padding-bottom: 55px;
}
.course-single-text .course-title b {
  font-weight: 700;
}
.course-single-text .course-title h3 {
  font-size: 35px;
  font-weight: 300;
}

.course-single-text p {
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 30px;
  color: #333333;
}

.course-details-category span {
  font-weight: 300;
  font-size: 28px;
  float: left;
  margin-right: 15px;
}
.course-details-category span b {
  font-weight: 700;
}

.course-details-category li {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  background-color: #f7f7f7;
  padding: 7px 15px;
  border-radius: 4px;
  margin-top: 5px;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
.course-details-category li:hover {
  color: #fff;
}

.course-details-item {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.affiliate-market-guide .section-title-2 h2:after,
.course-review .section-title-2 h2:after {
  display: none;
}

.affiliate-market-accordion .panel-group .panel {
  max-width: inherit;
  max-width: inherit;
  border: 1px solid #e5e5e5;
  padding: 10px 20px;
  border-top: none;
}

.affiliate-market-accordion .panel-group .panel-body {
  padding: 10px 50px 25px 80px;
  line-height: 1.8;
}

.affiliate-market-accordion .panel-group .btn-link.collapsed,
.affiliate-market-accordion .panel-group .panel-title .btn-link,
.affiliate-market-accordion .panel-group .panel-body {
  color: #333;
}

.affiliate-market-accordion .panel-group .panel-title h3:before {
  display: none;
}

.affiliate-market-accordion .panel-group .panel-body:before {
  display: none;
}

.affiliate-market-accordion .panel-title .ac-head {
  font-size: 20px;
  font-size: 500;
  position: relative;
}
.affiliate-market-accordion .panel-title .ac-head span {
  font-size: 50px;
  font-weight: 500;
  float: left;
  line-height: 1;
  margin-right: 20px;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}

.affiliate-market-accordion .panel-group .btn-link.collapsed span {
  color: #eeeeee !important;
}

.affiliate-market-accordion .panel-group .panel-title .btn-link span {
  color: #17d0cf;
}

.affiliate-market-accordion .leanth-course {
  position: absolute;
  right: 60px;
  top: 20px;
}
.affiliate-market-accordion .leanth-course span {
  background-color: #f7f7f7;
  padding: 12px 20px;
  font-size: 12px !important;
  font-weight: 700;
  border-radius: 4px;
  text-transform: uppercase;
  color: #ccc !important;
}

.affiliate-market-accordion .panel-group .btn-link.collapsed:after {
  color: #d9d9d9;
}

.course-by {
  font-weight: 700;
  font-size: 12px;
  position: absolute;
  bottom: 15px;
  left: 80px;
}
.course-by b {
  color: #17d0cf;
  font-size: 12px !important;
}

.affiliate-market-accordion .panel-group .panel:first-child {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-top: 1px solid #e5e5e5;
}

.affiliate-market-accordion .panel-group .panel:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.course-review {
  border-bottom: 1px solid #eee;
  padding-bottom: 50px;
  margin-bottom: 30px;
}

.avrg-rating .avrg-rate {
  font-size: 50px;
  font-weight: 700;
  display: block;
  color: #17d0cf;
  margin: 10px 0px;
}

.avrg-rating li {
  color: #ffc926;
}

.avrg-rating .start-bar {
  width: 100px;
  height: 8px;
  display: inline-block;
  margin: 0px 10px;
  background-color: #eeeeee;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}

.avrg-rating .rating-overview {
  margin-top: 5px;
}
.avrg-rating .rating-overview:hover .start-bar {
  background-color: #17d0cf;
}

.review-option {
  display: inline-block;
  width: 100%;
}

.rating {
  display: inline-block;
  position: relative;
  font-size: 20px;
  float: right;
  margin-left: 10px;
}

.rating label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  cursor: pointer;
}
.rating label span {
  margin: 0px 2px;
  color: #eee;
}

.rating label:last-child {
  position: static;
}

.rating label:nth-child(1) {
  z-index: 5;
}

.rating label:nth-child(2) {
  z-index: 4;
}

.rating label:nth-child(3) {
  z-index: 3;
}

.rating label:nth-child(4) {
  z-index: 2;
}

.rating label:nth-child(5) {
  z-index: 1;
}

.rating label input,
.rating label .register-form-area select,
.register-form-area .rating label select,
.rating label .register-form-area textarea,
.register-form-area .rating label textarea {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.rating label .icon {
  float: left;
  color: transparent;
}

.rating label:last-child .icon {
  color: #000;
}

.rating:not(:hover) label input:checked ~ .icon,
.rating:not(:hover) label .register-form-area select:checked ~ .icon,
.register-form-area .rating:not(:hover) label select:checked ~ .icon,
.rating:not(:hover) label .register-form-area textarea:checked ~ .icon,
.register-form-area .rating:not(:hover) label textarea:checked ~ .icon,
.rating:hover label:hover input ~ .icon,
.rating:hover label:hover .register-form-area select ~ .icon,
.register-form-area .rating:hover label:hover select ~ .icon,
.rating:hover label:hover .register-form-area textarea ~ .icon,
.register-form-area .rating:hover label:hover textarea ~ .icon {
  color: #ffc926;
}

.rating label input:focus:not(:checked) ~ .icon:last-child,
.rating label .register-form-area select:focus:not(:checked) ~ .icon:last-child,
.register-form-area .rating label select:focus:not(:checked) ~ .icon:last-child,
.rating
  label
  .register-form-area
  textarea:focus:not(:checked)
  ~ .icon:last-child,
.register-form-area
  .rating
  label
  textarea:focus:not(:checked)
  ~ .icon:last-child {
  color: #000;
  text-shadow: 0 0 5px #ffc926;
}

.course-side-bar-widget h3 {
  font-size: 30px;
  font-weight: 300;
  color: #333;
  margin-bottom: 20px;
}
.course-side-bar-widget h3 span {
  font-weight: 700;
  color: #17d0cf;
}

.like-course {
  height: 50px;
  width: 50px;
  background-color: #eeeeee;
  color: #cccccc;
  display: inline-block;
  line-height: 50px;
  text-align: center;
  border-radius: 4px;
  margin-left: 5px;
}

.course-side-bar-widget .genius-btn {
  line-height: 50px;
  padding: 0px 15px;
}

.student-number {
  float: right;
  color: #17d0cf;
  font-size: 14px;
}

.enrolled-student {
  margin-top: 20px;
  display: inline-block;
  width: 100%;
}
.enrolled-student li {
  color: #ffc926;
}

.couse-feature {
  margin-top: 15px;
}
.couse-feature li {
  font-size: 17px;
  font-weight: 500;
  color: #c4c4c4;
  position: relative;
  padding: 5px 0px;
  border: 1px solid #eeeeee;
  border-left: none;
  border-right: none;
}
.couse-feature li:after {
  top: 5px;
  left: 110px;
  content: ":";
  position: absolute;
}
.couse-feature span {
  position: relative;
  float: right;
  color: #333333;
}

.listing-filter-form label {
  font-size: 14px;
  font-weight: 700;
  color: 700;
  color: #333333;
  display: block;
}

.listing-filter-form input,
.listing-filter-form .register-form-area select,
.register-form-area .listing-filter-form select,
.listing-filter-form .register-form-area textarea,
.register-form-area .listing-filter-form textarea,
.listing-filter-form select {
  height: 50px;
  border: none;
  padding: 15px;
  width: 100%;
  background-color: #f7f7f7;
}

/*---------------------------------------------------- */
/*Checkout  section*/
/*----------------------------------------------------*/
.checkout-section {
  padding: 80px 0px 0px;
}

.checkout-content .course-page-section {
  padding: 0;
}

.order-item .section-title-2 h2,
.order-payment .section-title-2 h2 {
  font-size: 28px;
}
.order-item .section-title-2 h2:after,
.order-payment .section-title-2 h2:after {
  display: none;
}

.check-dlt {
  position: absolute;
  top: 10px;
  right: 10px;
}
.check-dlt i {
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}

.order-item .section-title-2 h2 {
  font-size: 28px;
}
.order-item .section-title-2 h2:after {
  display: none;
}

.order-item tr:hover .check-dlt i {
  color: #e61d1d;
}

.checkbox label:after,
.radio label:after {
  content: "";
  display: table;
  clear: both;
}

.checkbox .cr,
.radio .cr {
  position: relative;
  display: inline-block;
  border: 1px solid #f0f0f0;
  border-radius: 0.25em;
  width: 1.3em;
  height: 1.3em;
  float: left;
  margin-right: 0.5em;
  background-color: #f7f7f7;
}

.radio .cr {
  border-radius: 50%;
}

.checkbox .cr .cr-icon,
.radio .cr .cr-icon {
  position: absolute;
  font-size: 0.8em;
  line-height: 0;
  top: 50%;
  left: 20%;
}

.radio .cr .cr-icon {
  margin-left: 0.04em;
}

.checkbox label input[type="checkbox"],
.checkbox label .register-form-area select[type="checkbox"],
.register-form-area .checkbox label select[type="checkbox"],
.checkbox label .register-form-area textarea[type="checkbox"],
.register-form-area .checkbox label textarea[type="checkbox"],
.radio label input[type="radio"],
.radio label .register-form-area select[type="radio"],
.register-form-area .radio label select[type="radio"],
.radio label .register-form-area textarea[type="radio"],
.register-form-area .radio label textarea[type="radio"] {
  display: none;
}

.checkbox label input[type="checkbox"] + .cr > .cr-icon,
.checkbox label .register-form-area select[type="checkbox"] + .cr > .cr-icon,
.register-form-area .checkbox label select[type="checkbox"] + .cr > .cr-icon,
.checkbox label .register-form-area textarea[type="checkbox"] + .cr > .cr-icon,
.register-form-area .checkbox label textarea[type="checkbox"] + .cr > .cr-icon,
.radio label input[type="radio"] + .cr > .cr-icon,
.radio label .register-form-area select[type="radio"] + .cr > .cr-icon,
.register-form-area .radio label select[type="radio"] + .cr > .cr-icon,
.radio label .register-form-area textarea[type="radio"] + .cr > .cr-icon,
.register-form-area .radio label textarea[type="radio"] + .cr > .cr-icon {
  -webkit-transform: scale(3) rotateZ(-20deg);
  -ms-transform: scale(3) rotate(-20deg);
  transform: scale(3) rotateZ(-20deg);
  opacity: 0;
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.checkbox label input[type="checkbox"]:checked + .cr > .cr-icon,
.checkbox
  label
  .register-form-area
  select[type="checkbox"]:checked
  + .cr
  > .cr-icon,
.register-form-area
  .checkbox
  label
  select[type="checkbox"]:checked
  + .cr
  > .cr-icon,
.checkbox
  label
  .register-form-area
  textarea[type="checkbox"]:checked
  + .cr
  > .cr-icon,
.register-form-area
  .checkbox
  label
  textarea[type="checkbox"]:checked
  + .cr
  > .cr-icon,
.radio label input[type="radio"]:checked + .cr > .cr-icon,
.radio label .register-form-area select[type="radio"]:checked + .cr > .cr-icon,
.register-form-area .radio label select[type="radio"]:checked + .cr > .cr-icon,
.radio
  label
  .register-form-area
  textarea[type="radio"]:checked
  + .cr
  > .cr-icon,
.register-form-area
  .radio
  label
  textarea[type="radio"]:checked
  + .cr
  > .cr-icon {
  -webkit-transform: scale(1) rotateZ(0deg);
  -ms-transform: scale(1) rotate(0deg);
  transform: scale(1) rotateZ(0deg);
  opacity: 1;
}

.checkbox label input[type="checkbox"]:disabled + .cr,
.checkbox label .register-form-area select[type="checkbox"]:disabled + .cr,
.register-form-area .checkbox label select[type="checkbox"]:disabled + .cr,
.checkbox label .register-form-area textarea[type="checkbox"]:disabled + .cr,
.register-form-area .checkbox label textarea[type="checkbox"]:disabled + .cr,
.radio label input[type="radio"]:disabled + .cr,
.radio label .register-form-area select[type="radio"]:disabled + .cr,
.register-form-area .radio label select[type="radio"]:disabled + .cr,
.radio label .register-form-area textarea[type="radio"]:disabled + .cr,
.register-form-area .radio label textarea[type="radio"]:disabled + .cr {
  opacity: 0.5;
}

.checkbox label {
  font-size: 18px;
  color: #333333;
  font-weight: 700;
}
.checkbox label .sub-text {
  font-weight: 400;
  font-size: 15px;
  padding-left: 35px;
  display: block;
}
.checkbox label .sub-text b {
  color: #17d0cf;
}

.save-credit {
  width: 80%;
  float: right;
  margin-top: 10px;
}

.payment-method {
  margin-top: 30px;
  padding: 35px;
  display: inline-block;
  margin-bottom: 45px;
  -webkit-box-shadow: 0px 2px 10px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 10px 3px rgba(0, 0, 0, 0.1);
}

.payment-method-header {
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 15px;
  display: inline-block;
  border-bottom: 1px solid #eee;
}

.payment-info {
  margin-bottom: 10px;
  display: inline-block;
  width: 100%;
}
.payment-info label {
  width: 20%;
  float: left;
  color: #999999;
  margin-top: 15px;
  font-weight: 500;
}
.payment-info input,
.payment-info .register-form-area select,
.register-form-area .payment-info select,
.payment-info .register-form-area textarea,
.register-form-area .payment-info textarea {
  width: 80%;
  height: 50px;
  background-color: #f9f9f9;
  border-radius: 4px;
}
.payment-info input ::-webkit-input-placeholder,
.payment-info .register-form-area select ::-webkit-input-placeholder,
.register-form-area .payment-info select ::-webkit-input-placeholder,
.payment-info .register-form-area textarea ::-webkit-input-placeholder,
.register-form-area .payment-info textarea ::-webkit-input-placeholder {
  color: #999999;
  font-weight: 500;
}

.input-2 input,
.input-2 .register-form-area select,
.register-form-area .input-2 select,
.input-2 .register-form-area textarea,
.register-form-area .input-2 textarea {
  width: 25%;
  float: left;
  margin-right: 10px;
}

.terms-text {
  border-bottom: 1px solid #eee;
}
.terms-text b {
  color: #17d0cf;
}

.sub-total-title {
  font-size: 12px;
  font-weight: 700;
  color: #333333;
}

.purchase-list {
  background-color: #f7f7f7;
  padding: 40px 25px 30px 25px;
}
.purchase-list ul li {
  margin-bottom: 10px;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 10px;
}
.purchase-list ul li span {
  float: right;
  color: #333333;
  font-weight: 700;
}
.purchase-list ul li:last-child {
  border-bottom: 5px solid #e5e5e5;
}
.purchase-list .in-total {
  font-size: 20px;
  font-weight: 700;
  color: #333333;
}
.purchase-list .in-total span {
  color: #17d0cf;
  float: right;
}

/*---------------------------------------------------- */
/*Contact  section*/
/*----------------------------------------------------*/
.footer-area-section {
  padding: 105px 0px 0px 0px;
}

.footer-about-text p {
  font-size: 17px;
  margin-bottom: 25px;
  color: #333333;
}

.footer-menu {
  float: left;
  margin-left: 70px;
}
.footer-menu li {
  margin-bottom: 10px;
  color: #333333;
  font-size: 17px;
}
.footer-menu li i {
  margin-right: 10px;
  color: #17d0cf;
}

.footer-widget .photo-list li {
  position: relative;
  margin-bottom: 3px;
}
.footer-widget .photo-list li .pop-up-icon {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  opacity: 0;
}
.footer-widget .photo-list li .pop-up-icon i {
  color: #fff;
}
.footer-widget .photo-list li:hover .blakish-overlay {
  opacity: 1;
  visibility: visible;
}
.footer-widget .photo-list li:hover .pop-up-icon {
  opacity: 1;
}

.footer-social li,
.subscribe-form li {
  height: 30px;
  width: 30px;
  border-radius: 100%;
  line-height: 30px;
  margin-right: 8px;
  border: 2px solid #17d0cf;
  color: #666666;
  text-align: center;
}

.footer-social .widget-title,
.subscribe-form .widget-title {
  margin-bottom: 40px;
}
.footer-social .widget-title:after,
.subscribe-form .widget-title:after {
  bottom: -15px;
}

.subscribe-form {
  margin-left: 70px;
}

.copy-right-menu {
  padding: 30px 0px;
  border-top: 1px solid #ddd6d6;
}
.copy-right-menu p {
  margin: 0;
}

.footer_2_subs {
  max-width: 735px;
  margin: 0 auto;
  margin-top: 35px;
  margin-bottom: 60px;
}
.footer_2_subs p {
  color: #fff;
  margin-bottom: 45px;
}

.footer_2 .copy-right-text {
  color: #fff;
}

.footer_2 .copy-right-menu-item li {
  color: #fff;
  margin-left: 20px;
}

.footer_2 .footer-social li {
  color: #fff;
}

/*---------------------------------------------------- */
